import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import './Navbar.scss';
import routes from '../../router/routes';
import { useDispatch, useSelector } from 'react-redux';
import { authLogout } from '../../redux/actions/auth.actions';
import logo from '../../assets/logoSmall.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menuToggle, setMenuToggle] = useState(false);

    const logout = () => {
        localStorage.removeItem('token');
        dispatch(authLogout());
        navigate(routes.home);
        navigate(0);
    };

    return (
        <div style={{marginTop: 70}}>
            <nav id="navbar">
                <div className="nav-wrapper">
                    <div className="logo" style={{padding: 1}}>
                        <Link to={routes.home}>
                            <img src={logo} alt="logo" style={{height: '40%', objectFit: 'cover', transform: 'scale(3)', backgroundColor: "white"}} />
                        </Link>
                    </div>
                    <div className='navbar_display'>
                        <ul id="menu">
                            <li><Link to={routes.home}>Inicio</Link></li>
                            <li><Link to={routes.products}>Productos</Link></li>
                            {auth.logged && <li id="nosotros"><Link to={routes.aboutWe}>Sobre nosotros</Link></li>}
                            {!auth.logged && <li id="nosotros-one"><Link to={routes.aboutWe}>Sobre nosotros</Link></li>}
                            
                            
                            {!auth.logged && <li id="login"><Link to={routes.login}>Login</Link></li>}
                            {!auth.logged && <li id="register"><Link to={routes.register}>Register</Link></li>}

                            {auth.logged && <li id="carrito"><Link to={routes.cart}>Carrito de compras</Link></li>}
                            {auth.logged && <li id="dashboard"><Link to={routes.dashboard}>Dashboard</Link></li>}
                            {auth.logged && <li id="membresia"><Link to={routes.actualizeMembership}>Membresía</Link></li>}
                            {auth.logged && <li id="logout"><Link onClick={() => logout()}>Logout</Link></li>}
                            {auth.roles && ( auth.roles.includes('admin') || auth.roles.includes('moderator') ) && <li id="admin"><Link to={routes.adminPanel}>Admin</Link></li>}
                        </ul>
                    </div>

                    {auth.logged && !auth.roles.includes('admin') && <div className="dropdown">
                        <button className="button">...</button>
                        <div className="dropdown-content">
                            <li id="nosotros-one"><Link to={routes.aboutWe}>Sobre nosotros</Link></li>
                            <li id="carrito-one"><Link to={routes.cart}>Carrito de compras</Link></li>
                            <li id="dashboard-one"><Link to={routes.dashboard}>Dashboard</Link></li>
                            <li id="membresia-one"><Link to={routes.actualizeMembership}>Membresía</Link></li>
                            <li id="logout-one"><Link onClick={() => logout()}>Logout</Link></li>
                        </div>
                    </div>}

                    {auth.roles && auth.roles.includes('admin') && <div className="dropdown-two">
                        <button className="button">...</button>
                        <div className="dropdown-content">
                            <li id="nosotros-one"><Link to={routes.aboutWe}>Sobre nosotros</Link></li>
                            <li id="carrito-one"><Link to={routes.cart}>Carrito de compras</Link></li>
                            <li id="dashboard-one"><Link to={routes.dashboard}>Dashboard</Link></li>
                            <li id="membresia-one"><Link to={routes.actualizeMembership}>Membresía</Link></li>
                            <li id="logout-one"><Link onClick={() => logout()}>Logout</Link></li>
                            {auth.roles && auth.roles.includes('admin') && <li id="admin-one"><Link to={routes.adminPanel}>Admin</Link></li>}
                        </div>
                    </div>}

                    {!auth.logged && <div className="dropdown-one">
                        <button className="button">...</button>
                        <div className="dropdown-content">
                            <li id="nosotros-two"><Link to={routes.aboutWe}>Sobre nosotros</Link></li>
                            <li id="login-one"><Link to={routes.login}>Login</Link></li>
                            <li id="register-one"><Link to={routes.register}>Register</Link></li>
                        </div>
                    </div>}
                </div>
                <div className="menuIcon" onClick={() => setMenuToggle(!menuToggle)}>
                    <span className="icon icon-bars"></span>
                    <span className="icon icon-bars overlay"></span>
                </div>
            </nav>



            <div className={menuToggle ? 'overlay-menu active' : 'overlay-menu'}>
                <FontAwesomeIcon icon={faXmark} onClick={() => setMenuToggle(false)} id='close-overlay-menu' />

                <ul id="menu">
                    <li onClick={() => setMenuToggle(false)}><Link to={routes.home}>Inicio</Link></li>
                    <li onClick={() => setMenuToggle(false)}><Link to={routes.products}>Productos</Link></li>
                    <li onClick={() => setMenuToggle(false)}><Link to={routes.aboutWe}>Sobre nosotros</Link></li>
                    {auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.cart}>Carrito de compras</Link></li>}
                    {!auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.login}>Login</Link></li>}
                    {!auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.register}>Registrarse</Link></li>}
                    {auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.actualizeMembership}>Membresías</Link></li>}
                    {auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.dashboard}>Dashboard</Link></li>}
                    {auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.userConfig}>Editar perfil</Link></li>}
                    {auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.userHistory}>Mis pedidos</Link></li>}
                    {auth.logged && <li onClick={() => setMenuToggle(false)}><Link to={routes.userSearch}>Agenda Biznes</Link></li>}
                    {auth.logged && <li onClick={() => setMenuToggle(false)}><Link onClick={() => logout()}>Logout</Link></li>}

                    {auth.roles && auth.roles.includes('admin') && <li onClick={() => setMenuToggle(false)}><Link to={routes.adminPanel}>Admin</Link></li>}
                </ul>
                
            </div>
        </div>
    );
};

export default Navbar;
