import axios from 'axios';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import logo from '../../../assets/logo.png';
import env from '../../../env';
import '../CreateForm.scss';

const CreateCouponForm = () => {
    const [description, setDescription] = useState(null);
    const [code, setCode] = useState(null);
    const [link, setLink] = useState(null)
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const auth = useSelector(state => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!description || !code || !link ) return setError('Todos los campos son obligatorios.');

        axios.post(`${env.API_URL}/linkTicket`, {
            description,
            code,
            link
        }, {
            headers: {
                userid: auth._id,
                token: auth.token
            }
        })
            .then(res => {
                setSuccess(true)
                setError(false);
            })
            .catch(err => {
                setError(err.response.data.message)
                setSuccess(false);
            });
    }

    return (
        <form action="" id='create-category-form'>
            <img src={logo} alt="Logo Networking" />

            <div className="form-group">
                <label htmlFor="description">Descripción (esto aparecerá en la compra del usuario)
                    <span style={{ fontSize: 14, marginLeft: 10, color: '#00000090' }} >(esto le ayudará a usted a reconocerlo)</span>
                </label>

                <input type="text" name="description" id="description" className="form-control" onChange={(e) => setDescription(e.target.value)} />
            </div>

            <div className="form-group">
                <label htmlFor="code">Código
                    <span style={{ fontSize: 14, marginLeft: 10, color: '#00000090' }} >(sensible a mayúsculas y minúsculas)</span>
                </label>

                <input type="text" name="code" id="code" className="form-control" onChange={(e) => setCode(e.target.value)} />
            </div>

            <div className="form-group">
                <label htmlFor="link">Sub-Link a utilizar (https://www.tienda.biznes/card/...)
                    <span style={{ fontSize: 14, marginLeft: 10, color: '#00000090' }} >(sensible a mayúsculas y minúsculas)</span>
                </label>

                <input type="text" name="link" id="link" className="form-control" onChange={(e) => setLink(e.target.value)} />
            </div>

            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>CREAR</button>

            {success && <p className="text-success">Ticket creado correctamente.</p>}

            {error && <p className="text-danger">{error}</p>}
        </form>
    );
}

export default CreateCouponForm;
