import React from "react";
import CreateCouponForm from "../../components/admin-panel/create-coupon/CreateCouponForm";

const CreateCoupon = () => {
  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Crear cupón de descuento
      </h1>

      <CreateCouponForm />
    </div>
  );
};

export default CreateCoupon;
