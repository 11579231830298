import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import DashboardContent from '../components/dashboard/DashboardContent';
import DashboardNav from '../components/dashboard/DashboardNav';
import './DashboardPage.scss'

export default function Dashboard() {
    const auth = useSelector(state => state.auth)

    useEffect(() => {
      window.scrollTo(0, 0);
      
  }, []);
  
  if(auth.logged) return (
    <main className='dashboard_main' style={{display: 'flex', minHeight: '100vh', marginTop: 120}}>
        <DashboardNav auth={auth}/>
        <DashboardContent auth={auth}/>
    </main>
  )
}
