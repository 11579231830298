import React, { useState, useEffect } from "react";
import axios from "axios";
import env from "../../../env";

const ManageComments = () => {
  const [comments, setComments] = useState([]);

  const handleComments = () => {
    axios
      .get(`${env.API_URL}/comments`)
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleComments();
  }, []);

  const aproveComment = (id) => {
    axios
      .put(`${env.API_URL}/comments/approve/${id}`)
      .then((res) => {
        handleComments();
      })
      .catch((err) => console.log(err));
  };

  const removeComment = (id) => {
    axios
      .put(`${env.API_URL}/comments/reject/${id}`)
      .then((res) => {
        handleComments();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        marginTop: "30px",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <h1
        style={{
          marginBottom: "30px",
          fontSize: "20px",
          width: "100%",
          textAlign: "center",
        }}
      >
        Manejar comentarios
      </h1>

      {comments.length > 0 ? (
        comments.map((comment) => (
          <div
            key={comment._id}
            style={{
              width: "24%",
              padding: 20,
              background: "#ccc",
              textAlign: "center",
              borderRadius: 10,
              marginLeft: 20,
            }}
          >
            <p style={{ marginBottom: 20 }}>
              {comment.author.firstName} {comment.author.lastName}
            </p>
            <p>{comment.comment}</p>

            <p>{comment.aproved}</p>

            {!comment.aproved && (
              <button
                onClick={(e) => aproveComment(comment._id)}
                style={{
                  borderRadius: 50,
                  background: "var(--card-color)",
                  border: "none",
                  padding: "10px 20px",
                  marginTop: 30,
                  cursor: "pointer",
                }}
              >
                Agregar comentario al slider
              </button>
            )}

            {comment.aproved && (
              <button
                onClick={(e) => removeComment(comment._id)}
                style={{
                  borderRadius: 50,
                  background: "var(--card-color)",
                  border: "none",
                  padding: "10px 20px",
                  marginTop: 30,
                  cursor: "pointer",
                }}
              >
                Quitar comentario del slider
              </button>
            )}
          </div>
        ))
      ) : (
        <p>No hay comentarios</p>
      )}
    </div>
  );
};

export default ManageComments;
