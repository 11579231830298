import axios from 'axios';
import React, { useState } from 'react';
import env from '../../../env';
import {motion} from 'framer-motion';
import { TailSpin } from 'react-loader-spinner';

const ContactForm = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [form, setForm] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if(!form.name || !form.email || !form.message) return setError('Complete todos los campos');

        axios.post(`${env.API_URL}/emails/request`, form)
            .then(res => {
                setSuccess(true)
                setForm({});
                setError(false);
                setLoading(false);
            })
            .catch(err => {
                setError(true);
                setSuccess(false);
                setLoading(false);
            });
    }

    if(loading) return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column'}}>
            <TailSpin color='#000' height={100} width={100} />
            <h2 style={{marginTop: 20, fontSize: 24}}>Enviando mensaje...</h2>
        </div>
    )

    return (
        <form action="" className='contact_form' data-animation="appear">
            <div className="data_group" style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="form-group" style={{width: '45%'}}>
                    <label htmlFor="name">Nombre</label>
                    <input onChange={(e) => handleChange(e)} className='contact_form_input' type="text" name="name" id="name" placeholder='Ingrese su nombre' />
                </div>
                
                <div className="form-group" style={{width: '45%'}}>
                    <label htmlFor="email">Email</label>
                    <input onChange={(e) => handleChange(e)} className='contact_form_input' type="email" name="email" id="email" placeholder='Ingrese su email' />
                </div>

            </div>

            <div className="form-group">
                <label htmlFor="type">Asunto</label>
                <select className='contact_form_input' onChange={(e) => handleChange(e)} name="type" id="type">
                    <option value="Consulta">Consulta</option>
                    <option value="Sugerencia">Sugerencia</option>
                    <option value="Reclamo">Reclamo</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="message">Mensaje</label>
                <textarea className='contact_form_input' onChange={(e) => handleChange(e)} name="message" id="message" cols="30" rows="5" placeholder='Ingrese su mensaje'
                style={{height: '80px', padding: 10, margin: 0, fontFamily: 'inherit'}}></textarea>
            </div>

            {success && <motion.p animate={{transform: 'scale(1)'}} className="message_status success">Mensaje enviado con éxito!</motion.p>}
            {error && <motion.p animate={{transform: 'scale(1)'}} className="message_status error">{error}</motion.p>}

            {!loading && <button type="submit" onClick={(e) => handleSubmit(e)} className='main_button'>Enviar</button>}
        </form>
    );
}

export default ContactForm;
