import React, { useState } from 'react';
import './Dashboard.scss'
import { AnimatePresence, motion } from 'framer-motion';
import NoCards from './NoCards';
import { CardDataProvider } from '../card/CardData.provider';
import CardModal from '../card/CardModal';
import { Link } from 'react-router-dom';
import CreateCardButton from './CreateCardButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo.png';
import logoSmall from '../../assets/logoSmall.png';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import apiFunctions from '../../api/api';
import { authLogin } from '../../redux/actions/auth.actions';
import { useDispatch } from 'react-redux';
import LoadingModal from '../../globals/LoadingModal';

export default function DashboardContent({ auth, isBlocked }) {
    const [qrActivated, setQrActivated] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false)
    const {enqueueSnackbar} = useSnackbar();
    const { id } = useParams();
    const dispatch = useDispatch();
    console.log(auth._id)

    const transitionDuration = 1;

    const limitCards = () => {
        const permission = auth.membership[0].permissions.find(permission => permission.permission === 'Tarjetas');

        return permission.limit
    }

    const deleteCard = async (id) => {
        setIsDeleting(true);
        const response = await apiFunctions.deleteCard(id, auth._id);
        
        if(response.success) {
            enqueueSnackbar('Tarjeta eliminada correctamente', {variant: 'success'});
            dispatch(authLogin(response.user));
            setIsDeleting(false);
        } else {
            enqueueSnackbar('Error al eliminar la tarjeta', {variant: 'error'});
            setIsDeleting(false);
        }
    }

    return (
        <div className='dashboard_content'>

            {isDeleting && <LoadingModal />}

            {qrActivated &&
                <motion.div className="qr_modal_container" transition={{ duration: transitionDuration }} animate={{ opacity: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)' }}>
                    <img src={qrActivated} alt="IMÁGEN QR ACTIVADA" />
                    <a href={qrActivated} download>Descargar QR</a>
                    <FontAwesomeIcon icon={faXmark} onClick={(e) => setQrActivated(false)} />
                </motion.div>}

            <motion.div className="cards_container" transition={{ duration: transitionDuration }} animate={{ opacity: 1 }}>
                {!id ? auth.cards.length === 0 && <NoCards setIsCreating={setIsCreating} /> : auth.cards.length === 0 && <h1 style={{ textAlign: 'center', fontSize: 30, width: '100%' }}>Este usuario no tiene tarjetas</h1>}

                {auth.cards.length > 0 && auth.cards.map((card, index) => (
                    <div className="card" key={index}>
                        {!id &&
                            <>
                                <button className='edit_button' onClick={(e) => setIsEditing(card)} style={{
                                    right: 40
                                }}>
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>

                                <button className='edit_button' onClick={(e) => deleteCard(card._id)}>
                                    <FontAwesomeIcon icon={faTrash} color='var(--color-danger)' />
                                </button>
                            </>
                        }

                        <div className="card_dashboard_preview">
                            <div className="card_dashboard_preview__header">
                                <img style={{ width: '100%', height: '100%', borderTopLeftRadius: 15, borderTopRightRadius: 15, borderBottomLeftRadius: 0 }}
                                    src={card.coverPhoto !== "false" ? card.coverPhoto : logo} alt="cover" />

                                <div className="card_dashboard_preview__header__userphoto">
                                    <img className='perfil_photo' src={card.perfilImage} alt="user" />
                                    <img className='enterprise_logo' src={card.logoPhoto !== "false" ? card.logoPhoto : logoSmall} alt="logo" />
                                </div>
                            </div>

                            <div className="card_dashboard_preview__header__info">
                                <h3 style={{ textAlign: "center" }}>{card.name}</h3>

                                <div className="position">
                                    {!card.jobPosition && !card.jobEntity ? null :
                                        <p id='preview_job_position' style={card.cardStyle.job}>{card.jobPosition || ''} - {card.jobEntity || ''}</p>
                                    }
                                </div>
                            </div>

                            <Link className='link' target={'_blank'} to={`/card/${card.cardLink}`} style={{ background: 'var(--color-logo)', color: 'black', fontWeight: '500' }}>Ver tarjeta</Link>

                            <img src={card.imageQr} alt="imágen QR" style={{ width: 50, height: 50, position: 'absolute', left: '40%', bottom: 20, cursor: 'pointer' }}
                                onClick={(e) => setQrActivated(card.downloadQr)} />
                        </div>
                    </div>
                ))}

                {auth.cards.length > 0 && auth.cards.length < limitCards() && !id && <CreateCardButton setIsCreating={setIsCreating} />}

                {auth.cards.length > 0 && auth.cards.length >= limitCards() && !id &&
                    <div className="limit_reached" style={{ display: auth.cards.length === 5 ? 'none' : 'block', width: '100%' }}>
                        <p style={{ marginBottom: 30, fontWeight: '400' }}>Has alcanzado el límite de tarjetas</p>
                        {auth.cards.length < 5 && <p style={{ marginBottom: 0, fontWeight: '400' }}>Para crear más tarjetas, debes actualizar tu plan</p>}
                    </div>}

            </motion.div>

            <AnimatePresence>
                <CardDataProvider auth={auth}>
                    {isCreating && <CardModal setIsCreating={setIsCreating} />}

                    {isEditing && <CardModal setIsCreating={setIsEditing} isEditing={isEditing} />}
                </CardDataProvider>
            </AnimatePresence>
        </div>
    )
}
