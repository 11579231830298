import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DashboardNav from '../components/dashboard/DashboardNav';
import UserSearchContent from '../components/user-search/UserSearchContent';
import axios from 'axios';
import env from '../env';

const UserSearch = () => {
    const auth = useSelector(state => state.auth);
    const [users, setUsers] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(false)
        axios.get(`${env.API_URL}/biznesagenda/getAllUsers`, {
            headers: {
                userid: auth._id,
                token: auth.token
            }
        })
        .then(res => {
            setUsers(res.data);
            setIsLoaded(true)
        })
        .catch(err => {
            console.log(err)
            setIsLoaded(true)
        });

        window.scrollTo(0, 0);
        
    }, []);

    if(auth) return (
        <main className='dashboard_main' style={{display: 'flex', minHeight: '100vh', marginTop: 120}}>
            <DashboardNav auth={auth} />
            {isLoaded && <UserSearchContent users={users}/>}
        </main>
    );
}

export default UserSearch;
