import React, { useContext } from 'react'
import { CardDataContext } from '../CardData.provider.jsx';
import '../DesignForm.scss';

const AdditionalsButtonsDesign = () => {
    const {cardData, handleEditStylesAdditionalButton} = useContext(CardDataContext);

    return (
        cardData.additionalButtons && cardData.additionalButtons[0] && cardData.additionalButtons.map((button, index) => (
            <div className="form_section" key={index}>
                <h2>Botón adicional #{index + 1}</h2>
                
                <div className="form_group">
                    <label htmlFor="name">Tamaño de la letra</label>
                    <input className="buttonContact" defaultValue={cardData.additionalButtons[index] && cardData.additionalButtons[index].styles.fontSize} onChange={(e) => handleEditStylesAdditionalButton(e, index)} type="range" name="fontSize" id={`ab_${index}_pixels_fs`} min="14" max="20" />
                </div>

                <div className="form_group">
                    <label htmlFor="name">Color de la letra</label>
                    <input className="buttonContact" defaultValue={cardData.additionalButtons[index] && cardData.additionalButtons[index].styles.color} onChange={(e) => handleEditStylesAdditionalButton(e, index)} type="color" name="color" id={`ab_${index}_color`} />
                </div>

                <div className="form_group">
                    <label htmlFor="name">Separación entre letras</label>
                    <input className="buttonContact" defaultValue={cardData.additionalButtons[index] && cardData.additionalButtons[index].styles.letterSpacing} onChange={(e) => handleEditStylesAdditionalButton(e, index)} type="range" name="letterSpacing" id={`ab_${index}_pixels_ls`} min="0" max="5" />
                </div>

                <div className="form_group">
                    <label htmlFor="name">Color de fondo</label>
                    <input className="buttonContact" defaultValue={cardData.additionalButtons[index] && cardData.additionalButtons[index].styles.backgroundColor} onChange={(e) => handleEditStylesAdditionalButton(e, index)} type="color" name="backgroundColor" id={`ab_${index}_color_bg`}  />
                </div>

                <div className="form_group">
                    <label htmlFor="name">Radio de borde</label>
                    <input className="buttonContact" defaultValue={cardData.additionalButtons[index] && cardData.additionalButtons[index].styles.borderRadius} onChange={(e) => handleEditStylesAdditionalButton(e, index)} type="range" name="borderRadius" id={`ab_${index}_pixels_br`} min="0" max="30" />
                </div>

                <div className="form_group">
                    <label htmlFor="name">Ancho</label>
                    <input className="buttonContact" defaultValue={cardData.additionalButtons[index] && cardData.additionalButtons[index].styles.width} onChange={(e) => handleEditStylesAdditionalButton(e, index)} type="range" name="width" id={`ab_${index}_w_percent`} min="50" max="100" />
                </div>

                <div className="form_group">
                    <label htmlFor="ancho">Alto</label>
                    <input className="buttonContact" defaultValue={cardData.additionalButtons[index] && cardData.additionalButtons[index].styles.height} onChange={(e) => handleEditStylesAdditionalButton(e, index)} type="range" name="height" id={`ab_${index}_height_pixels`} min="20" max="100" />
                </div>
            </div>
    ))
  )
}

export default AdditionalsButtonsDesign