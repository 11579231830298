import React, { useState, useEffect } from "react";
import CreateUserForm from "../../components/admin-panel/create-user/CreateUserForm";

const CreateModUser = () => {
  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowsWidth(window.innerWidth);
    });
  }, []);

  return (
    <section
      style={{
        width: windowsWidth < 600 ? "100%" : "80%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 40,
        marginBottom: 50,
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>Crear usuario</h1>

      <CreateUserForm />
    </section>
  );
};

export default CreateModUser;
