import React from "react";
import { useSelector } from "react-redux";
import EditInfoForm from "../../../components/admin-panel/biznesInfo/EditInfoForm";

const ManageBiznesInfo = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px 0",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Modificar información de la página
      </h1>

      <EditInfoForm auth={auth} />
    </div>
  );
};

export default ManageBiznesInfo;
