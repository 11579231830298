import axios from 'axios';
import React, { useState, useEffect } from 'react';
import env from '../../env';
import ProductsFilter from './ProductsFilter';
import ProductCard from '../../globals/ProductCard';
import './ProductsContainer.scss';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const ProductsContainer = ({ }) => {
    const [products, setProducts] = useState([]);
    const [filters, setFilters] = useState(false);
    const [windowsSize, setWindowsSize] = useState(window.innerWidth);
    const [categoryQuery, setCategoryQuery] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {

        if (searchParams.get('category')) {
            setCategoryQuery(searchParams.get('category'));

            setFilters({
                ...filters,
                categories: [searchParams.get('category')]
            })

            axios.post(`${env.API_URL}/product/filters`, {
                ...filters,
                categories: [searchParams.get('category')]
            })
                .then(res => setProducts(res.data))
                .catch(err => console.log(err));
        }

        window.addEventListener('resize', () => {
            setWindowsSize(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        if (filters) {
            axios.post(`${env.API_URL}/product/filters`, filters)
                .then(res => setProducts(res.data))
                .catch(err => console.log(err));
        }

        if (!filters) {
            axios.get(`${env.API_URL}/product`)
                .then(res => setProducts(res.data))
                .catch(err => console.log(err));
        }
    }, [filters]);

    return (
        <div className='general'>
            <ProductsFilter setFilters={setFilters} setProducts={setProducts} filters={filters} />

            <div className='products_container' id='products_container' data-animation="appear">
                {products && products.map((el, index) => {
                    return <ProductCard key={uuidv4()} product={el} width={windowsSize > 1570 ? '23%' : windowsSize > 1450 ? '25%' : windowsSize > 1348 ? '27%' : windowsSize > 1225 ? '30%' : windowsSize > 965 ? '40%' : windowsSize > 855 ? '45%' : windowsSize > 822 ? '47%' : windowsSize > 600 ? '45%' : '100%'} />
                })}
            </div>
        </div>
    );
}

export default ProductsContainer;
