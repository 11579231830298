import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import env from "../../../env";
import "../../../components/admin-panel/CardStyle.scss";
import "./ManageOrders.scss";
import { useSnackbar } from "notistack";

const ManageEnterpriseMembership = () => {
    const [form, setform] = useState({
        email: "",
    });
    const [memberships, setMemberships] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const handleChange = (e) => {
        setform({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const getMemberships = async () => {
        const res = await axios.get(`https://empresas-api.biznes.cl/api/membership`);
        setMemberships(res.data);

        if(!res.data.length){
            enqueueSnackbar("No hay membresias disponibles", {variant: "warning"});
        }
    }

    useEffect(() => {
        getMemberships();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(!form.email || !form.membership || !form.days){
            enqueueSnackbar("Debe completar todos los campos", {variant: "warning"});
            return;
        };

        const res = await axios.post(`https://empresas-api.biznes.cl/api/membership/assign`, {
            email: form.email,
            subscription: form.membership,
            days: form.days
        });

        if(!res.data.status) {
            enqueueSnackbar(res.data.message, {variant: "error"});
            return;
        }

        enqueueSnackbar(res.data.message, {variant: "success"});
    }

    return (
        <>
            <div
                className="container"
                style={{
                    width: "100%",
                    minHeight: "100%",
                    display: "flex",
                    marginTop: "30px",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
                    Asignar membresia a empresa
                </h1>

                <form className="purchase_form" onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group contact">
                        <input type="email" onChange={(e) => handleChange(e)} className="form-control" name="email" placeholder="E-mail" />
                    </div>

                    <div className="form-group address">
                        <select name="membership" id="membership" onChange={(e) => handleChange(e)}>
                            <option value="">Seleccione una membresia</option>
                            {memberships.map((membership) => (
                                <option value={membership._id}>{membership.description}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group address">
                        <input type="number" className="form-control" name="days" placeholder="Días" onChange={(e) => handleChange(e)} />
                    </div>

                    <div className="form-group address">
                        <input type="submit" className="form-control btn" name="submit" placeholder="Asignar" />
                    </div>
                </form>

            </div>

        </>
    );
};

export default ManageEnterpriseMembership;
