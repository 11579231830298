import React, { useState, useEffect } from 'react';
import  './ManageAnouncement.scss';
import axios from 'axios';
import env from '../../../env';
import { useSelector } from 'react-redux';


const ManageAnouncement = () => {
    const [form, setForm] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const auth = useSelector(state => state.auth);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSend = (e) => {
        e.preventDefault();
        setIsLoading(true);

        if(!form.titlePartOne || !form.titleDestacado || !form.titleDestacadoTwo || !form.titlePartTwo || !form.descriptionOne || !form.descriptionTwo || !form.buttonOneText || !form.buttonOneLink || !form.buttonTwoText || !form.buttonTwoLink || !form.disclaimer || !form.images) return setError('Debes completar todos los campos');

        const formData = new FormData();

        formData.append('titlePartOne', form.titlePartOne);
        formData.append('titleDestacado', form.titleDestacado);
        formData.append('titleDestacadoTwo', form.titleDestacadoTwo);
        formData.append('titlePartTwo', form.titlePartTwo);
        formData.append('descriptionOne', form.descriptionOne);
        formData.append('descriptionTwo', form.descriptionTwo);
        formData.append('buttonOneText', form.buttonOneText);
        formData.append('buttonOneLink', form.buttonOneLink);
        formData.append('buttonTwoText', form.buttonTwoText);
        formData.append('buttonTwoLink', form.buttonTwoLink);
        formData.append('disclaimer', form.disclaimer);
        formData.append('images', form.images);

        axios.put(`${env.API_URL}/anouncement`, formData, {
            headers: {
                userid: auth._id,
            }
        })
        .then(res => {
            setSuccess(true);
            setIsLoading(false);
            setError(false);
        })
        .catch(err => {
            console.error(err);
            setError('Ha ocurrido un error, inténtalo de nuevo');
            setSuccess(false);
            setIsLoading(false);
        })
    }

    return (
        <form className='create_form'>
            <div className="form_group">
                <label htmlFor="disclaimer">Disclaimer</label>
                <textarea name="disclaimer" id="disclaimer" cols="30" rows="10" onChange={(e) => handleChange(e)}></textarea>
            </div>

            <div className="form_group">
                <label htmlFor="titlePartOne">Parte superior del título</label>
                <input type="text" name="titlePartOne" id="titlePartOne" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="titleDestacado">Parte destacada izquierda del título</label>
                <input type="text" name="titleDestacado" id="titleDestacado" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="titleDestacadoTwo">Parte destacada derecha del título</label>
                <input type="text" name="titleDestacadoTwo" id="titleDestacadoTwo" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="titlePartTwo">Parte inferior del título</label>
                <input type="text" name="titlePartTwo" id="titlePartTwo" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="descriptionOne">Descripción Superior</label>
                <textarea name="descriptionOne" id="descriptionOne" cols="30" rows="10" onChange={(e) => handleChange(e)}></textarea>
            </div>

            <div className="form_group">
                <label htmlFor="descriptionTwo">Descripción Inferior</label>
                <textarea name="descriptionTwo" id="descriptionTwo" cols="30" rows="10" onChange={(e) => handleChange(e)}></textarea>
            </div>

            <div className="form_group">
                <label htmlFor="buttonOneText">Texto botón izquierdo</label>
                <input type="text" name="buttonOneText" id="buttonOneText" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="buttonOneLink">Link botón izquierdo</label>
                <input type="text" name="buttonOneLink" id="buttonOneLink" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="buttonTwoText">Texto botón derecho</label>
                <input type="text" name="buttonTwoText" id="buttonTwoText" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="buttonTwoLink">Link botón derecho</label>
                <input type="text" name="buttonTwoLink" id="buttonTwoLink" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form_group">
                <label htmlFor="images">Imágen</label>
                <input type="file" name="images" id="images" onChange={(e) => {
                    setForm({
                        ...form,
                        images: e.target.files[0]
                    })
                }} />
            </div>

            {error && <p className='error'>{error}</p>}
            {success && <p className='success'>Anuncio actualizado con éxito</p>}

            <button onClick={(e) => handleSend(e)}>Enviar</button>
        </form>
    );
}

export default ManageAnouncement;
