import React, { useState, useEffect } from 'react';
import axios from 'axios';
import env from '../../../env';
import { ThreeCircles } from 'react-loader-spinner';
import colors from '../../../styles/colors';
import CategoriesPicker from './CategoriesPicker';
import ProductCard from '../../../globals/ProductCard';
import { Link } from 'react-router-dom';

const ProductsContainer = () => {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [windowsSize, setWindowsSize] = useState(window.innerWidth);

    useEffect(() => {
        setIsLoading(true);

        axios.get(`${env.API_URL}/product`)
            .then(res => {
                // get 5 products without offer, if there are less than 5 products without offer, get the rest of products

                const productsWithOffer = res.data.filter((product, index) => product.priceWithOffer && index < 5);

                const productsWithoutOffer = res.data.filter(product => !product.priceWithOffer);
                if (productsWithOffer.length < 5) {
                    const productsToGet = 5 - productsWithOffer.length;
                    for (let i = 0; i < productsToGet; i++) {
                        if (productsWithoutOffer[i]) productsWithOffer.push(productsWithoutOffer[i])
                    }
                }

                setProducts(productsWithOffer);
            })
            .catch(err => {
                setIsError(true);
            });

        setIsLoading(false);

        window.addEventListener('resize', () => {
            setWindowsSize(window.innerWidth);
        });

    }, []);

    if (isLoading) return (
        <div>
            <ThreeCircles
                height={100}
                width={100}
                color={colors.grey}
                loading={isLoading}
            />
        </div>
    )

    if (isError) return (
        <div>
            <h1>Something went wrong</h1>
        </div>
    )

    return (
        <div>
            <h2 style={{ textAlign: 'center', fontSize: 40, marginTop: 30 }}>¡Adquiere productos biznes para tu networking!</h2>

            <div className="products_container" style={{ display: 'flex', padding: '40px', justifyContent: 'flex-start', flexWrap: 'wrap', width: '100%' }}>
                {products.map((product, index) => {
                    if (index <= 5) {
                        return (
                            <ProductCard key={index} product={product} index={windowsSize > 1340 ? '19%' : windowsSize > 1100 ? '23%' : windowsSize > 868 ? '30%' : windowsSize > 580 ? '48%' : windowsSize > 500 ? '45%' : '100%'} />
                        );
                    }
                })}
            </div>

            <Link to="/products" style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 30, textDecoration: 'none' }}>
                <button className='main_button'>Ver todo</button>
            </Link>

        </div>
    );
}

export default ProductsContainer;
