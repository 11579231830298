import React from "react";
import CreateMembership from "../../components/admin-panel/create-membership/CreateMembership";
import { MembershipDataProvider } from "../../components/admin-panel/create-membership/CreateMembershipData.provider";

const CreateMembershipPage = () => {
  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: 20,
        alignItems: "center",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Crear categoría
      </h1>

      <MembershipDataProvider>
        <CreateMembership />
      </MembershipDataProvider>
    </div>
  );
};

export default CreateMembershipPage;
