import React from 'react'
import AppRouter from './router/AppRouter'
import { Provider } from 'react-redux'
import store from './redux/store'
import { SnackbarProvider } from 'notistack'

function App() {
  return (
    <SnackbarProvider autoHideDuration={1000} anchorOrigin={
      { vertical: 'bottom', horizontal: 'center' }
    } maxSnack={3} >
      <Provider store={store}>
        <div className="App">
          <AppRouter />
        </div>
      </Provider>
    </SnackbarProvider>
  )
}

export default App
