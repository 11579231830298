import React from 'react';
import HomeHowItWorks from './HomeHowItWorks';

const HomeHowItWorksRenderer = () => {
    return (
        <HomeHowItWorks />
    );
}

export default HomeHowItWorksRenderer;
