export default [{
    name: 'rojo',
    hex: '#dc3545'
},{
    name: 'azul',
    hex: '#007bff'
}, {
    name: 'verde',
    hex: '#28a745'
}, {
    name: 'amarillo',
    hex: '#ffc107'
}, {
    name: 'naranja',
    hex: '#fd7e14'
}, {
    name: 'púrpura',
    hex: '#6f42c1'
}, {
    name: 'rosa',
    hex: '#e83e8c'
}, {
    name: 'gris',
    hex: '#6c757d'
}, {
    name: 'negro',
    hex: '#343a40'
}, {
    name: 'blanco',
    hex: '#f8f9fa'
}, {
    name: 'marron',
    hex: '#795548'
}, {
    name: 'turquesa',
    hex: '#20c997'
}, {
    name: 'cyan',
    hex: '#17a2b8'
}, {
    name: 'indigo',
    hex: '#6610f2'
}, {
    name: 'celeste',
    hex: '#63c2de'
}, {
    name: 'verde claro',
    hex: '#8bc34a'
}, {
    name: 'verde lima',
    hex: '#cddc39'
}]