import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HashLoader } from 'react-spinners';
import env from '../../env';
import routes from '../../router/routes';

export default function CancellingMembership() {
    const [modal, setModal] = useState(false);
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useSelector(state => state.auth);

    const handleCancelMembership = async () => {
        setIsLoading(true);

        axios.put(`${env.API_URL}/membershipPayment/cancel`, {
            userId: auth._id
        })
            .then(res => {
                window.location.href = routes.dashboard;
            })
            .catch(err => {
                console.error(err);
                setError(true);
                setIsLoading(false);
            });
    }

    return (
        <>
            {modal && (
                <div className='cancel_membership_modal'>
                    <div className='modal_content'>
                        <h2 className='modal_title'>¿Estás seguro de que quieres cancelar tu membresía?</h2>
                        <p>Si cancelas tu membresía, no podrás volver a acceder a los contenidos de la plataforma.</p>
                        <div className='modal_buttons' style={{marginBottom: error || isLoading ? 30 : 0}}>
                            <button className='cancel_button' onClick={() => setModal(false)}>Cancelar</button>
                            <button className='confirm_button' onClick={() => handleCancelMembership()}>Confirmar</button>
                        </div>

                        {error && (
                            <p style={{margin: 0, marginTop: 30}} className='error_message'>Ha ocurrido un error, por favor intenta de nuevo.</p>
                        )}

                        {isLoading && (
                            <HashLoader color='#00A8E8' size={40} />
                        )}
                    </div>
                </div>
            )}

            <button onClick={(e) => {
                e.preventDefault();
                setModal(true);
            }} className='cancel_membership_button'>
                <a href='/dashboard'>Cancelar membresía</a>
            </button>
        </>
    )
}
