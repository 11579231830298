import React, { useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import MercadoPagoMembresia from '../actualize-membership/MercadoPagoMembresía';
import { useNavigate } from 'react-router-dom';
import routes from '../../router/routes';
import { useSelector } from 'react-redux';

import actualizeUser from "../../helpers/ActualizeUser"
import axios from 'axios';
import env from '../../env'; 


const ConfirmMembershipModal = ({membership, setIsPurchasing, period, couponCode}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [paymentLink, setPaymentLink] = useState(null);
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    //--------------- USO LOCAL DE PAGO ------------------
     const handleSend = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${env.API_URL}/membershipPayment/test/${auth._id}/${membership._id}/${period}`)
        .then(res => {
            setIsLoading(false);
            setIsSuccess(true);

            actualizeUser();
        })
        .catch(err => {
            setIsLoading(false);
            setIsError(true);
        });
    } 
    console.log(auth);
    console.log(membership);
    if(isLoading) return (
        <div className='confirm_membership_modal'>
            <form>
                <h1>Actualizando membresía...</h1>
                <TailSpin />
            </form>
        </div>
    );

    if(isSuccess) return (
        <div className='confirm_membership_modal'>
            <form>
                <h1>Confirmar membresía</h1>
                <p>¡Membresía actualizada con éxito!</p>

                <div className="buttons">
                    <button className='confirm' onClick={() => setIsPurchasing(false)}>Aceptar</button>
                    <button className="" onClick={() => navigate(routes.dashboard)}>Ir a mi perfil</button>
                </div>
            </form>
        </div>
    );

    if(isError) return (
        <div className='confirm_membership_modal'>
            <form>
                <h1>Fallo al actualizar la membresía.</h1>
                <p style={{color: 'var(--color-danger)', fontSize: 20}}>Ha ocurrido un error al actualizar la membresía.</p>
                
                <div className="buttons">
                    <button className='' onClick={() => setIsPurchasing(false)}>Cerrar modal</button>
                </div>
            </form>
        </div>
    );
    console.log(paymentLink)
    if(!isLoading) return (
        
        <div className='confirm_membership_modal'>
            <form>
                <h1>Confirmar membresía</h1>
                <p>¿Estás seguro de que actualizar a membresía {membership.name}?</p>
                <p className="disclaimer">Al actualizar la membresía, el mes de tu membresía actual se cobrará de todas formas.</p>
                <p className="disclaimer" style={{fontWeight: '600', color: 'var(--color-danger)'}}>¡¡UNA VEZ PAGADO, HAGA CLICK EN "VOLVER AL SITIO"!!</p>

                <div className="buttons" style={{display: "flex"}}>
                    <button className='cancel' onClick={() => setIsPurchasing(false)}>Cancelar</button>
                    { 
                        <a href={paymentLink} className='confirm' target="_blank">
                            <button className='confirm'>Confirmar</button>
                        </a>
                    }
                    {/* //--------------- USO LOCAL DE PAGO ------------------ */}
                    {
                        // <button className='confirm' onClick={(e) => handleSend(e)}>Confirmar Local</button> 
                    }
                    <MercadoPagoMembresia 
                        couponCode={couponCode}
                        cart={membership} 
                        setPaymentLink={setPaymentLink} 
                        membership={membership._id} 
                        period={period} 
                        paymentLink={paymentLink}
                    />
                </div>

                
            </form>
        </div>
    );
}

export default ConfirmMembershipModal;
