import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import env from "../../../env";
import { AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const inputs = [
  {
    name: "description",
    type: "text",
    label: "Descripción",
  },
  {
    name: "code",
    type: "text",
    label: "Código",
  },
  {
    name: "link",
    type: "text",
    label: "Sub-Link",
  }
];

const ManageTicketsLinks = () => {
  const [linksTickets, setLinksTicket] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [linkSelected, setLinkSelected] = useState(null);
  const [form, setForm] = useState({});
  const [success, setSuccess] = useState(false);
  const [errores, setErrores] = useState(false);
  const auth = useSelector((state) => state.auth);

  const actualizeTickets = () => {
    axios
      .get(`${env.API_URL}/linkTicket`)
      .then((res) => setLinksTicket(res.data.data))
      .catch((err) => alert("Error al cargar los tickets."));
  };

  useEffect(() => {
    actualizeTickets();
  }, [selectedId]);

  useEffect(() => {
    if (selectedId && linksTickets) {
      linksTickets.map((link) => {
        if (link._id === selectedId) {
          setLinkSelected(link);
        }
      });
    }
  }, [selectedId, linksTickets]);

  const reset = () => {
    setSelectedId(null);
    setSuccess(false);
    setErrores(false);
    setForm({});
  };

  const handleEdit = (e) => {
    e.preventDefault();
    axios.put(`${env.API_URL}/linkTicket/${selectedId._id}`, form, {
      headers: {
        userid: auth._id,
      },
    })

    .then((res) => {
      setSuccess(true);
      setErrores(false);
      reset();
      actualizeTickets();
    })
    .catch((err) => {
      setSuccess(false);
      setErrores(true);
    });
  };

  const handleDelete = (id) => {
    axios.delete(`${env.API_URL}/linkTicket/${id}`, {
      headers: {
        userid: auth._id,
      },
    })

    .then((res) => {
      setSuccess(true);
      setErrores(false);
      reset();
      actualizeTickets();
    })
    .catch((err) => {
      setSuccess(false);
      setErrores(true);
    });
  };

  return (
    <>
      <div
        className="container"
        style={{
          width: "100%",
          minHeight: "100%",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
          Gestionar tickets de links
        </h1>

        {linksTickets.map((link) => (
          <div key={link._id} style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "80%",
            marginBottom: "20px",
            padding: "20px",
            border: "1px solid black",
            borderRadius: "10px",
            backgroundColor: "var(--card-color)",
            color: 'white',
            position: 'relative'
          }}> 
            <FontAwesomeIcon icon={faEdit} style={{
              position: 'absolute',
              top: '20px',
              right: '20px',
              color: 'black',
              cursor: 'pointer',
              height: '20px'
            }} onClick={
              () => setSelectedId(link)
            }/>

            <FontAwesomeIcon icon={faTrash} style={{
              position: 'absolute',
              top: '20px',
              right: '50px',
              color: 'var(--color-danger)',
              cursor: 'pointer',
              height: '20px'
            }} onClick={() => handleDelete(link._id)}/>

            <p style={{marginRight: '50px'}}>{link.description}</p>

            <p>{link.code}</p>
          </div>
        ))}

        
      </div>

      <AnimatePresence>
        {selectedId && (
          <div
            layoutId={selectedId._id}
            style={{
              position: "fixed",
              background: "#00000080",
              width: "100vw",
              height: "100vh",
              top: 0,
              left: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <div
              layoutId={selectedId._id}
              style={{
                background: "white",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "10px",
                padding: "20px 50px",
                width: "50%",
                position: "relative",
              }}
            >

              <FontAwesomeIcon icon={faXmark} style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                color: 'black',
                cursor: 'pointer',
                height: '20px'
              }} onClick={reset}/>

              <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
                Editar ticket de link
              </h1>

              <form style={{width: '100%'}}>
                {inputs.map((input) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    <label
                      htmlFor={input.name}
                      style={{ marginBottom: "10px", fontSize: "16px" }}
                    >
                      {input.label}
                    </label>

                    <input
                      type={input.type}
                      name={input.name}
                      id={input.name}
                      style={{
                        border: "none",
                        borderRadius: "10px",
                        padding: "10px",
                        fontSize: "16px",
                        color: "black",
                        width: "100%",
                      }}
                      defaultValue={selectedId[input.name]}
                      onChange={(e) =>
                        setForm({ ...form, [input.name]: e.target.value })
                      }
                    />

                  </div>

                ))}

                {errores && (
                  <p style={{color: 'var(color-danger)'}}>
                    El ticket no pudo ser editado.
                  </p>
                )}

                <button
                  type="submit"
                  style={{
                    background: "var(--card-color)",
                    color: "black",
                    border: "none",
                    borderRadius: "10px",
                    padding: "10px",
                    fontSize: "16px",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  onClick={(e) => {
                    handleEdit(e);
                  }}
                >
                  Editar
                </button>
              </form>

              </div>
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ManageTicketsLinks;