import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HashLoader } from 'react-spinners';
import DashboardContentEnterprise from '../components/dashboard/DashboardContentEnterprise';
import env from '../env';
import './DashboardPage.scss'

export default function UserEnterprisePage() {
    const { id } = useParams();
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        axios.get(`${env.API_URL}/users/${id}`)
            .then(res => {
                setUser(res.data)
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                setError(err.response.data.message);
            });
    }, [id]);

  if(isLoading) return (
    <main className='dashboard_main' style={{display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center', marginTop: 120}}>
        <HashLoader color='#f50057' size={50}/>
    </main>
  )

  if(error) return (
    <main className='dashboard_main' style={{display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center', marginTop: 120}}>
        <h1 style={{fontSize: 30}}>Ha ocurrido un error al cargar la página: {error}</h1>
    </main>
  )

  if(user) return (
    <main className='dashboard_main' style={{display: 'flex', minHeight: '100vh', marginTop: 120}}>
        <DashboardContentEnterprise auth={user}/>
    </main>
  )
}
