import React from 'react';
import {motion } from 'framer-motion';
import './UserSearch.scss';
import { useNavigate } from 'react-router-dom';
import routes from '../../router/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faBuilding, faUserPlus, faUserMinus } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import env from '../../env';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {authLogin} from '../../redux/actions/auth.actions';

const UserCard = ({user}) => {
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const checkIfFriend = () => auth.agendaFriends.enterprise.includes(user._id) || auth.agendaFriends.networking.includes(user._id);

    const handleAdd = () => {
        axios.post(`${env.API_URL}/biznesagenda/addFriend`, {friendId: user._id}, {
            headers: {
                authorization: `Bearer ${auth._id}`
            }
        }).then(res => {
            dispatch(authLogin({
                token: auth.token,
                ...res.data
            }))
        }).catch(err => {
            console.log(err);
        })
    }

    const handleRemove = () => {
        axios.post(`${env.API_URL}/biznesagenda/removeFriend`, {friendId: user._id}, {
            headers: {
                authorization: `Bearer ${auth._id}`
            }
        }).then(res => {
            dispatch(authLogin({
                token: auth.token,
                ...res.data
            }))
        }).catch(err => {
            console.log(err);
        })
    }
    

    return (
        <motion.div className='user_card' whileHover={{scale: 1.1, cursor: 'pointer'}} whileTap={{scale: 0.9}}
        animate={{transform: 'scale(1)'}} transition={{duration: 0.45}}>
                <img src={user.userImage} alt={user.firstName} style={{width: 80, height: 80, borderRadius: 50}} />
                <p style={{color: 'black', textAlign: 'center'}}>{user.firstName} {user.lastName}</p>
                {user.privacyType === 'private' && <p style={{color: 'black', textAlign: 'center'}}>Usuario pro</p>}
                {user.privacyType === 'public' && <p className='user_email'>{user.email}</p>}

                {user.privacyType === 'public' && !user.isEnterpriseUser && <button onClick={() => navigate(`${routes.userPagePath}/${user._id}`)}>Ver tarjetas</button>}
                {user.privacyType === 'public' && user.isEnterpriseUser && <button onClick={() => navigate(`${routes.userEnterprisePath}/${user._id}`)}>Ver tarjetas</button>}

                <div className="icons_container">
                    {user.privacyType === 'private'  && <FontAwesomeIcon icon={faLock} />}
                    {user.isEnterpriseUser && <FontAwesomeIcon icon={faBuilding} />}
                </div>

                {checkIfFriend() ? <FontAwesomeIcon icon={faUserMinus} onClick={() => handleRemove()} className='add_user_icon' /> :  <FontAwesomeIcon icon={faUserPlus} onClick={() => handleAdd()} className='add_user_icon' />}
        </motion.div>
    );
}

export default UserCard;
