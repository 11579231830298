import React, { useState, useEffect } from "react";
import Slider from "react-animated-slider";
import "./slider-animations.css";
import "./styles-sliderhome.scss";
import "react-animated-slider/build/horizontal.css";
import env from "../../../env";
import axios from "axios";
import { Link } from "react-router-dom";

export default () => {
  const [content, setContent] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    axios
      .get(`${env.API_URL}/news`)
      .then((res) => {
        setContent(res.data);
      })
      .catch((err) => console.log(err));

    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
  }, []);

  const desktopStyles = {
    inner: { padding: "50px 0 50px 250px", fontWeight: "500" },
    disclaimer: {
      backgroundColor: "#959295",
      color: "white",
      textAlign: "center",
      fontSize: 20,
      display: "inline",
      padding: "0 20px",
    },
    title: { width: "45%", fontSize: 60, lineHeight: 1.1 },
  };

  return (
    <div data-animation="appear">
      <Slider className="slider-wrapper">
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url('${item.image}') no-repeat center center`,
            }}
          >
            <div
              className="inner"
              style={windowWidth > 768 ? desktopStyles.inner : {}}
            >
              <p
                className="disclaimer"
                style={windowWidth > 768 ? desktopStyles.disclaimer : {}}
              >
                {item.disclaimer}
              </p>
              <h1 style={windowWidth > 768 ? desktopStyles.title : {}}>
                {item.title}
              </h1>
              <p style={{whiteSpace: 'pre-line'}}>{item.description}</p>

              <div className="buttons">
                <Link style={{ color: "white" }} to={item.linkButtonOne}>
                  {item.textButtonOne}
                </Link>

                <Link to={item.linkButtonTwo}>{item.textButtonTwo}</Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
