import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import env from '../../env';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../router/routes';
import './Footer.scss';

const Footer = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        axios.get(`${env.API_URL}/networking`).then((response) => {
            setData(response.data);
        })
            .catch((err) => {
                setError(true);
        });

        axios.get(`${env.API_URL}/categories`).then((response) => {
            setCategories(response.data);
        })
            .catch((err) => {
                setError(true);
        });

    }, []);

    return (
        <footer style={{ textAlign: "center"}} className="footer_background">
        <div data-animation="appear" className="footer">
            <div className="footer_section">
                <div className="footer_section_title">
                    <h3>Ubicación de tienda</h3>
                </div>
                <hr/>
                <div className="footer_section_content">
                    <p>{data.street}</p>
                    <p>{data.city}</p>
                    <p>{data.region}</p>
                    <p>{data.email}</p>
                    <p>{data.phone}</p>

                    <div className="footer_section_content_social" style={{marginTop: 5}}>
                        <FontAwesomeIcon icon={faFacebook} size={"xl"} style={{ marginRight: 10}} color="#ffffff"/>
                        <FontAwesomeIcon icon={faInstagram} size={"xl"} style={{ marginRight: 10}} color="#ffffff" />
                        <FontAwesomeIcon icon={faTwitter} size={"xl"} style={{ marginRight: 10}} color="#ffffff" />
                        <FontAwesomeIcon icon={faYoutube} size={"xl"} style={{ marginRight: 10}} color="#ffffff" />
                    </div>
                </div>
            </div>

            <div className="footer_section">
                <div className="footer_section_title">
                    <h3>Tienda</h3>
                </div>

                <hr/>

                <div className="footer_section_content">
                    {categories.map((category) => {
                        return (
                            <Link key={category._id} to={`${routes.products}?category=${category.name}`}>
                                {category.name}
                            </Link>
                        );
                    }   
                    )}
                </div>
            </div>

            <div className="footer_section">
                <div className="footer_section_title">
                    <h3>Atención al cliente</h3>
                </div>

                <hr/>

                <div className="footer_section_content">
                    <a href={`${routes.home}#contact_form_container`}>Contáctanos</a>
                    <Link to={`${routes.aboutWe}#contact_form_container`}>Sobre nosotros</Link>
                </div>
            </div>

            <div className="footer_section">
                <div className="footer_section_title">
                    <h3>Política</h3>
                </div>

                <hr/>
                
                <div className="footer_section_content">
                    <Link to={routes.termsAndConditions}>Envío y devoluciones</Link>
                    <Link to={routes.termsAndConditions}>Términos y condiciones</Link>
                    <Link to={routes.termsAndConditions}>Métodos de pago</Link>
                    <Link to={routes.faqs}>FAQ</Link>
                </div>
            </div>

            
        </div>
            <div style={{width: "70%", marginLeft: "15%"}}>
                <hr/>
            </div>
            <div><h3 style={{color: "white", margin: "40px 0px"}}>Copyright © 2023. Proyecto Realizado por VPortal.</h3></div>
        </footer>
    );
}

export default Footer;
