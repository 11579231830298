import React from 'react';
import HeroCards from './HeroCards';

const HeroCardsRenderer = () => {
    return (
        <HeroCards />
    );
}

export default HeroCardsRenderer;
