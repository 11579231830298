import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import env from '../env';
import DashboardNav from '../components/dashboard/DashboardNav';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import routes from '../router/routes';
import './UserHistory.scss'
import { faFile } from '@fortawesome/free-solid-svg-icons';


const UserHistory = () => {
    const [history, setHistory] = useState([]);
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if(auth) {
            axios.get(`${env.API_URL}/orders/${auth._id}`)
            .then(res => {
                setHistory(res.data);
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [auth]);

    return (
        <main className='main_container' >
            <DashboardNav auth={auth}/>

            <div className="container" style={{
                width: '100%', minHeight: '100%', display: 'flex', marginTop: '30px',
                flexDirection: 'column', alignItems: 'center'
            }}>
                <h1 style={{ marginBottom: '30px', fontSize: '20px' }}>Gestionar ordenes</h1>

                {history.map(purchase => (
                        <motion.div key={purchase._id} style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className='card'>
                                <h3 className='name_order' style={{marginRight: 30, color: 'white'}}> {purchase.buyer.firstName} {purchase.buyer.lastName} </h3>
                                <h3 className='date_order' style={{marginRight: 30,color: 'white'}}> Fecha de la orden: {purchase.createdAt} </h3>

                                <Link className='details_order' to={`${routes.orderPath}/${purchase._id}`} style={{textDecoration: 'none', position: 'absolute', color: 'white'}}>
                                    <FontAwesomeIcon icon={faFile} className='view_more' color="white"/>
                                </Link>
                            </div>
                        </motion.div>
                ))}
            </div>
        </main>
    );
}

export default UserHistory;
