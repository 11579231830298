import {
    faHouse, 
    faStar, 
    faEnvelope, 
    faHeart, 
    faCameraRetro, 
    faCloud, 
    faCartShopping, 
    faCircleInfo, 
    faFolderOpen,
    faLink,
    faHashtag,
    faStore
} from '@fortawesome/free-solid-svg-icons';

const customMedia = [{
    name: 'CustomIcon 1',
    placeholder: "https://tu-link.com",
    icon: faHouse,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
}, 
{
    name: 'Custom Icon 2',
    placeholder: "https://tu-link.com",
    icon: faStar,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 3',
    placeholder: "https://tu-link.com",
    icon: faEnvelope,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 4',
    placeholder: "https://tu-link.com",
    icon: faHeart,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 5',
    placeholder: "https://tu-link.com",
    icon: faCameraRetro,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 6',
    placeholder: "https://tu-link.com",
    icon: faCloud,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 7',
    placeholder: "https://tu-link.com",
    icon: faCartShopping,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 8',
    placeholder: "https://tu-link.com",
    icon: faCircleInfo,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 9',
    placeholder: "https://tu-link.com",
    icon: faFolderOpen,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 10',
    placeholder: "https://tu-link.com",
    icon: faLink,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 11',
    placeholder: "https://tu-link.com",
    icon: faHashtag,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
{
    name: 'Custom Icon 12',
    placeholder: "https://tu-link.com",
    icon: faStore,
    color: '#1992CF',
    contrast: '#FFFFFF',
    favorite: false
},
];

export default customMedia;