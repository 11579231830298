import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import env from "../../../env";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import "../../../components/admin-panel/CardStyle.scss";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    axios
      .get(`${env.API_URL}/users`, {
        headers: { userid: auth._id },
      })
      .then((res) => setUsers(res.data))
      .catch((err) => alert("Error al cargar los cupones."));
  }, []);

  return (
    <>
      <div
        className="container"
        style={{
          width: "100%",
          minHeight: "100%",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
          Gestionar usuarios
        </h1>

        <a style={{
          backgroundColor: "#1D6F42",
          width: '80%',
          height: '50px',
          borderRadius: '10px',
          marginBottom: '30px'
        }} href={`${env.API_URL}/users/excel`}>
          <button className="btn btn-primary" style={{
            backgroundColor: "#1D6F42",
            width: '100%',
            height: '50px',
            borderRadius: '10px',
            marginBottom: '30px'
          }}>Descargar EXCEL de usuarios</button>
        </a>

        {users.map((user) => (
          <motion.div
            layoutId={user._id}
            key={user._id}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <div className="card">
              <h3 style={{ color: "white" }}>Username: {user.username}</h3>
              <h3 style={{ color: "white" }}>UserId: {user.userId}</h3>
              <h3 style={{ marginRight: 30, color: "white" }}>{user.email}</h3>
              {user.roles &&
                user.roles.map((role) => (
                  <span style={{ color: "white" }}>{role.name}/</span>
                ))}

              <div className="card__buttons">
                <FontAwesomeIcon icon={faTrash} className="delete" />
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default ManageUsers;
