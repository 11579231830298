import React from "react";
import CreateBrandForm from "../../components/admin-panel/brands/CreateBrandForm";

const CreateBrand = () => {
  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Crear imágen de "Marcas"
      </h1>

      <CreateBrandForm />
    </div>
  );
};

export default CreateBrand;
