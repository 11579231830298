import { faPinterest, faReddit, faDailymotion, faDeviantart, faFacebook, faFlickr, faSnapchat,
    faSoundcloud, faSpotify, faApple, faTelegram, faGooglePlus, faTumblr, faTwitter, faInstagram,
    faVimeo, faLinkedin, faWhatsapp, faYoutube, faTiktok, faBehance, faInternetExplorer} from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faWifi, faLocationDot } from '@fortawesome/free-solid-svg-icons';

    const socialMedia = [{
        name: 'Pinterest',
        placeholder: "https://www.pinterest.cl/tu-usuario",
        icon: faPinterest,
        color: '#E60023',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Reddit',
        placeholder: "https://www.reddit.com/user/tu-usuario",
        icon: faReddit,
        color: '#FF4500',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Dailymotion',
        placeholder: "https://www.dailymotion.com/tu-usuario",
        icon: faDailymotion,
        color: '#00A6D6',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Deviantart',
        placeholder: "https://www.deviantart.com/tu-usuario",
        icon: faDeviantart,
        color: '#05CC47',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Facebook',
        placeholder: "https://www.facebook.com/tu-id",
        icon: faFacebook,
        color: '#3B5998',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Flickr',
        placeholder: "https://www.flickr.com/people/tu-id",
        icon: faFlickr,
        color: '#0063DC',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Snapchat',
        placeholder: "https://www.snapchat.com/add/tu-id",
        icon: faSnapchat,
        color: '#FFFC00',
        contrast: '#000000',
        favorite: false
    }, {
        name: 'Soundcloud',
        placeholder: "https://soundcloud.com/tu-id",
        icon: faSoundcloud,
        color: '#FF3300',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Spotify',
        placeholder: "https://open.spotify.com/user/tu-id",
        icon: faSpotify,
        color: '#1DB954',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Apple',
        placeholder: "https://apps.apple.com/es/developer/tu-usuario/tu-id",
        icon: faApple,
        color: '#000000',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Telegram',
        placeholder: "https://web.telegram.org/z/tu-id",
        icon: faTelegram,
        color: '#0088CC',
        contrast: '#FFFFFF',
        favorite: false
    }, 
    // Ya no existe
    /* {
        name: 'Google Plus',
        placeholder: "",
        icon: faGooglePlus,
        color: '#DD4B39',
        contrast: '#FFFFFF',
        favorite: false
    }, */ {
        name: 'Tumblr',
        placeholder: "https://www.tumblr.com/tu-usuario",
        icon: faTumblr,
        color: '#35465C',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Twitter',
        placeholder: "https://twitter.com/tu-usuario",
        icon: faTwitter,
        color: '#1DA1F2',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Instagram',
        placeholder: "https://www.instagram.com/tu-usuario",
        icon: faInstagram,
        color: '#E1306C',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Vimeo',
        placeholder: "https://vimeo.com/tu-id",
        icon: faVimeo,
        color: '#1AB7EA',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Linkedin',
        placeholder: "https://www.linkedin.com/in/tu-usuario",
        icon: faLinkedin,
        color: '#0077B5',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Whatsapp',
        placeholder: "https://wa.me/tu-número",
        icon: faWhatsapp,
        color: '#25D366',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Youtube',
        placeholder: "https://www.youtube.com/@tu-id",
        icon: faYoutube,
        color: '#FF0000',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Tiktok',
        placeholder: "https://www.tiktok.com/@tu-id",
        icon: faTiktok,
        color: '#000000',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Behance',
        placeholder: "https://www.behance.net/tu-usuario",
        icon: faBehance,
        color: '#1769FF',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'Web',
        placeholder: "https://www.tu-sitio.com",
        icon: faGlobe,
        color: '#1992CF',
        contrast: '#FFFFFF',
        favorite: false
    }, {
        name: 'GoogleMaps',
        placeholder: "https://goo.gl/maps/tu-enlace",
        icon: faLocationDot,
        color: '#4285F4',
        contrast: '#FFFFFF',
    }, {
        name: 'Web2',
        placeholder: "https://www.tu-sitio2.com",
        icon: faWifi,
        color: '#1992CF',
        contrast: '#FFFFFF',
    }, {
        name: 'Web3',
        placeholder: "https://www.tu-sitio3.com",
        icon: faInternetExplorer,
        color: '#1992CF',
        contrast: '#FFFFFF',
    }];
    
    export default socialMedia;