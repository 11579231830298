import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DashboardNav from '../components/dashboard/DashboardNav';
import UserContactsSection from '../components/user-contacts/UserContactsSection';
import axios from 'axios';
import env from '../env';

const UserContacts = () => {
    const auth = useSelector(state => state.auth);
    const [friends, setFriends] = useState({})

    useEffect(() => {
        window.scrollTo(0, 0);

        axios.get(`${env.API_URL}/biznesagenda/getFriends/${auth._id}`)
            .then(res => {
                setFriends(res.data);
            })
            .catch(err => console.log(err));      
    }, []);

    if(auth && friends.enterprise && friends.networking) return (
        <main className='dashboard_main' style={{display: 'flex', minHeight: '100vh', marginTop: 120}}>
            <DashboardNav auth={auth} />

            <section className="" style={{width: '80%'}}>

                <UserContactsSection users={friends.enterprise} title={'Biznes empresa'} />
                <UserContactsSection users={friends.networking} title={'Biznes'} />
            </section>
        </main>
    );
}

export default UserContacts;