import React from "react";
import CreateNewsForm from "../../components/admin-panel/create-news/CreateNewsForm";

const CreateNews = () => {
  return (
    <div
      className="container"
      style={{
        margin: "30px 0",
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>Crear noticia</h1>

      <CreateNewsForm />
    </div>
  );
};

export default CreateNews;
