import React, { useEffect, useState } from 'react'
import apiFunctions from '../../../api/api'
import LoadingModal from '../../../globals/LoadingModal'
import './CoverPhoto.scss'
import axios from 'axios'
import env from '../../../env'
import { useSnackbar } from 'notistack'

export default function CoverPhoto() {
  const [oldImage, setOldImage] = useState("")
  const [newImage, setNewImage] = useState("")
  const {enqueueSnackbar} = useSnackbar()

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newImage) {
      enqueueSnackbar("No se ha seleccionado ninguna imagen", {variant: "error"})
      return
    }

    const formData = new FormData();
    formData.append("images", newImage[0]);

    const request = await axios.post(`${env.API_URL}/config/coverPhoto`, formData);
    
    enqueueSnackbar(request.data.message, {variant: 'success'})
  }

  useEffect(() => {
    const getConfig = async () => {
      const config = await apiFunctions.getConfig()
      setOldImage(config.config.backCardPhoto)
    }
    getConfig()
  }, [])

  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        marginTop: "30px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!oldImage && <LoadingModal />}

      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Administrar foto de portada por defecto
      </h1>

      <form className="purchase_form" onSubmit={(e) => handleSubmit(e)} style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <label htmlFor="coverPhoto" id='coverPhoto' style={{
          width: "80%",
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundImage: `url(${newImage ? URL.createObjectURL(newImage[0]) : oldImage})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          marginBottom: "30px",
          fontSize: "20px",
          cursor: "pointer",
        }}>
        </label>

        <input
          type="file"
          name="coverPhoto"
          id="coverPhoto"
          onChange={(e) => setNewImage(e.target.files)}
        />

        <button type="submit" className="btn btn-primary" style={{
          width: "80%",
          height: "50px",
          marginTop: "30px",
          marginBottom: "30px",
          fontSize: "20px",
          borderRadius: "5px",
        }}>
          Guardar
        </button>
      </form>

    </div>
  )
}
