import React, { useContext, useEffect, useState } from 'react';
import CardDataContext from './CardData.provider';
import "./devices.min.css"
import ReactDevicePreview from 'react-device-preview'

const MobilePreview = ({children}) => {
  const {cardData, handleInputs} = useContext(CardDataContext);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [relative, setRelative] = useState(0.0257142857142857);

  useEffect(() => {
    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight);
    });
  }, []);

  const styles = {transform: `scale(.${windowHeight - 50})`,
  position: 'relative', top: '15px'}

    if (cardData.mobilePreview === 'iphonex' || !cardData.mobilePreview) return (
      <div className="marvel-device iphone-x" style={styles}>
          <div className="notch">
              <div className="camera"></div>
              <div className="speaker"></div>
          </div>
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="bottom-bar"></div>
          <div className="volume"></div>
          <div className="overflow">
              <div className="shadow shadow--tr"></div>
              <div className="shadow shadow--tl"></div>
              <div className="shadow shadow--br"></div>
              <div className="shadow shadow--bl"></div>
          </div>
          <div className="inner-shadow"></div>
          <div className="screen">
              {children}
          </div>
      </div>
    );

    if (cardData.mobilePreview === 'galaxynote8') return (
        <div className="marvel-device note8" style={{...styles, transform: `scale(.${windowHeight - 80})`}}>
            <div className="inner"></div>
            <div className="overflow">
                <div className="shadow"></div>
            </div>
            <div className="speaker"></div>
            <div className="sensors"></div>
            <div className="more-sensors"></div>
            <div className="sleep"></div>
            <div className="volume"></div>
            <div className="camera"></div>
            <div className="screen">
                {children}
            </div>
        </div>
    );

    if (cardData.mobilePreview === 'iphone8') return (
        <div className="marvel-device iphone8 silver" style={styles}>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="volume"></div>
            <div className="camera"></div>
            <div className="sensor"></div>
            <div className="speaker"></div>
            <div className="screen">
                {children}
            </div>
            <div className="home"></div>
            <div className="bottom-bar"></div>
        </div>
    );

    if (cardData.mobilePreview === 'iphone8plus') return (
        <div className="marvel-device iphone8plus black" style={{...styles, transform: 'scale(.55)', left: '22%'}}>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="volume"></div>
            <div className="camera"></div>
            <div className="sensor"></div>
            <div className="speaker"></div>
            <div className="screen">
                {children}
            </div>
            <div className="home"></div>
            <div className="bottom-bar"></div>
        </div>
    );

    if (cardData.mobilePreview === 'iphone5s') return (
        <div className="marvel-device iphone5s" style={styles}>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="volume"></div>
            <div className="camera"></div>
            <div className="sensor"></div>
            <div className="speaker"></div>
            <div className="screen">
                {children}
            </div>
            <div className="home"></div>
            <div className="bottom-bar"></div>
        </div>
    );

    if (cardData.mobilePreview === 'iphone5c') return (
        <div className="marvel-device iphone5c green" style={styles}>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="volume"></div>
            <div className="camera"></div>
            <div className="sensor"></div>
            <div className="speaker"></div>
            <div className="screen">
                {children}
            </div>
            <div className="home"></div>
            <div className="bottom-bar"></div>
        </div>
    );

    if (cardData.mobilePreview === 'iphone4s') return (
        <div className="marvel-device iphone4s silver" style={styles}>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="volume"></div>
            <div className="camera"></div>
            <div className="sensor"></div>
            <div className="speaker"></div>
            <div className="screen">
                {children}
            </div>
            <div className="home"></div>
            <div className="bottom-bar"></div>
        </div>
    );

    if (cardData.mobilePreview === 'nexus5') return (
        <div className="marvel-device nexus5" style={styles}>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="volume"></div>
            <div className="camera"></div>
            <div className="screen">
                {children}
            </div>
        </div>
    );

    if (cardData.mobilePreview === 'galaxyS5') return (
        <div className="marvel-device s5 white" style={styles}>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="camera"></div>
            <div className="sensor"></div>
            <div className="speaker"></div>
            <div className="screen">
                {children}
            </div>
            <div className="home"></div>
        </div>
    );

    if (cardData.mobilePreview === 'htcone') return (
        <div className="marvel-device htc-one" style={styles}>
            <div className="top-bar"></div>
            <div className="camera"></div>
            <div className="sensor"></div>
            <div className="speaker"></div>
            <div className="screen">
                {children}
            </div>
        </div>
    );
}

export default MobilePreview;
