import React, { useState, useEffect } from "react";
import CreateProductSection from "../../components/admin-panel/create-product/CreateProductSection";

const CreateProduct = () => {
  const [windowsWidth, setWindowsWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowsWidth(window.innerWidth);
    });
  }, []);

  return (
    <section
      style={{
        width: windowsWidth < 600 ? "100%" : "80%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 40,
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>Crear producto</h1>

      <CreateProductSection />
    </section>
  );
};

export default CreateProduct;
