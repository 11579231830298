import React from "react";
import ManageAnouncement from "../../../components/admin-panel/manage-anouncement/ManageAnouncement";

const EditAnouncement = () => {
  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "30px 0",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>Crear FAQ</h1>

      <ManageAnouncement />
    </div>
  );
};

export default EditAnouncement;
