import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import env from '../../env';

const ThirdSection = ({textInput, userId, setStatus}) => {
    const password = useRef();
    const validatePassword = useRef();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        e.preventDefault(e);
        
        if(password.current.value !== validatePassword.current.value) return dispatch( setError('Las contraseñas no coinciden') )

        axios.put(`${env.API_URL}/changePassword/${userId}`, {
            newPassword: password.current.value
        })
            .then(res => setStatus(3))
            .catch( err => setError('Hubo un error en el servidor, inténtelo de nuevo') )
      };

    return (
        <>
            <input type="password" placeHolder="Ingresa nueva contraseña" ref={password}/>
            <input type="password" placeHolder="Repetir contraseña" ref={validatePassword}/>

            {error && <p style={{color: 'red'}}>{error}</p>}
            
            <input
            type="submit"
            value={textInput}
            onClick={(e) => handleChange(e)}
            style={{cursor: 'pointer'}}
            />
        </>
    );
}

export default ThirdSection;
