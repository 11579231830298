import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import env from "../../env";
import MercadoPagoPaymentCompra from "./MercadoPagoPaymentCompra";
import "./PaymentOptions.scss";

const formatNumbers = (number) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  let num = Math.trunc(number);
  return num.toString().replace(exp, rep);
};

const PaymentOptions = ({ products, form }) => {
  const [total, setTotal] = useState(0);
  const [totalWithCoupon, setTotalWithCoupon] = useState(0);
  const [couponSelected, setCouponSelected] = useState(false);
  const [aplyingCoupon, setAplyingCoupon] = useState(false);
  const [paymentID, setPaymentID] = useState(null);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [couponError, setCouponError] = useState(false);
  const [activeButton, setActiveButton] = useState(false);
  const couponCode = useRef();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //--------------- USO LOCAL DE PAGO ------------------
  /* const handleLocalhost = () => {
        axios.post(`${env.API_URL}/payments/try/${auth._id}/${form.region}/${form.commune}/${form.address}/${form.email}/${form.cellphone}`)
        .then(res => {
            dispatch(authLogin({
                token: auth.token,
                ...res.data
            }));
            setSuccess(true);
            navigate(routes.paymentSuccess)
        })
        .catch(err => console.log(err));
    } */

  useEffect(() => {
    let total = 0;
    products.forEach((product) => {
      if (product.priceWithOffer) {
        total += product.priceWithOffer * product.quantity;
      } else {
        total += product.price * product.quantity;
      }
    });

    if (auth.membershipDiscount) {
      const percentResult = (total * auth.membershipDiscount) / 100;
      total -= percentResult;
    }

    setTotal(total.toFixed(0));
  }, [products]);

  const handleAplyingCoupon = () => {
    axios
      .post(`${env.API_URL}/coupon/apply`, {
        code: couponCode.current.value,
        price: total,
      })
      .then((res) => {
        setTotalWithCoupon(res.data.total.toFixed(0));
        setCouponSelected(couponCode.current.value);
      })
      .catch((err) => {
        setCouponError(true);
      });
  };

  useEffect(() => {
    setActiveButton(false);

    if (form.region) {
      axios
        .get(`${env.API_URL}/shippingPrice/${form.region}`)
        .then((res) => {
          setShippingPrice(res.data.shippingPrice.price);
        })
        .catch((err) => console.log(err));
    }

    if (
      form.region &&
      form.commune &&
      form.address &&
      form.email &&
      form.cellphone
    ) {
      setTimeout(() => {
        setActiveButton(true);
      }, 500);
    }
  }, [form, couponSelected]);

  if (products.length > 0)
    return (
      <div className="payments_container">
        <h3 className="mount">
          Total productos:
          <span style={{ marginLeft: 10 }}>
            {totalWithCoupon > 0 && `$${formatNumbers(totalWithCoupon)}`}
          </span>
          <span className={totalWithCoupon ? "second" : "first"}>
            ${formatNumbers(total)}
          </span>
        </h3>

        {auth.membershipDiscount && (
          <div className="discount">
            <p
              style={{
                marginBottom: 20,
                fontWeight: 300,
                color: "#222222",
                fontSize: "14px",
              }}
            >
              Se aplicó descuento por membresía: {auth.membershipDiscount}%
            </p>
          </div>
        )}

        {shippingPrice > 0 && (
          <div className="discount">
            <p style={{ marginBottom: 30 }}>
              Gastos por envío: ${formatNumbers(shippingPrice)}
            </p>
          </div>
        )}

        {total > 0 && (
          <div className="discount">
            <p style={{ marginBottom: 30 }}>
              Total: ${formatNumbers(parseInt(shippingPrice) + (totalWithCoupon > 0 ? parseInt(totalWithCoupon) : parseInt(total)))}
            </p>
          </div>
        )}

        <button
          className="apply"
          onClick={() => setAplyingCoupon(!aplyingCoupon)}
        >
          Aplicar cupón
        </button>

        {aplyingCoupon && (
          <div className="coupon_input">
            <input type="text" placeholder="Código de cupón" ref={couponCode} />
            <button onClick={() => handleAplyingCoupon()}>Aplicar</button>
            {couponError && (
              <p style={{ marginLeft: 5, color: "var(--color-danger)" }}>
                Cupón inválido
              </p>
            )}
          </div>
        )}

        {activeButton && (
          <MercadoPagoPaymentCompra
            cart={products}
            setPaymentID={setPaymentID}
            couponSelected={couponSelected}
            paymentID={paymentID}
            form={form}
          />
        )}

        {/* --------------- USO LOCAL DE PAGO ------------------ */}
        {/* {form.region && form.commune && form.address && form.email && form.cellphone &&
                <button className='buy desactive' onClick={() => handleLocalhost()}>Confirmar compra</button>
            } */}
      </div>
    );
};

export default PaymentOptions;
