import React, { useState, useEffect } from 'react';
import './PurchaseForm.scss'
import regionesChile from './regiones';

const PurchaseForm = ({form, setForm}) => {
    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);

    useEffect(() => {
        const regionesFilter = regionesChile.map(data => data.region)
        setRegiones(regionesFilter)
    }, [])

    useEffect(() => {
        if(form.region) {
            const comunasFilter = regionesChile.filter(data => data.region === form.region)
            const comunas = comunasFilter[0].comunas
            setComunas(comunas)
        }
    }, [form]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    return (
        <form className='purchase_form'>
            <h2>Formulario de compra</h2>

                <div className="form-group address">
                    <select name="region" id="region" onChange={(e) => handleChange(e)}>
                        {!form.region && <option value="0">Seleccione su región</option>}
                        {regiones.map((region, index) => {
                            return <option key={index} value={region}>{region}</option>
                        })}
                        
                    </select>
                </div>

                <div className="form-group address">
                    <select name="commune" id="commune" onChange={(e) => handleChange(e)}>
                        {!form.commune && <option value="0">Seleccione su comuna</option>}
                        {comunas.map((commune, index) => {
                            return <option key={index} value={commune}>{commune}</option>
                        })}
                    </select>
                </div>

                <div className="form-group address">
                    <input type="text" className="form-control" name="address" placeholder="Dirección" onChange={(e) => handleChange(e)}/>
                </div>



                <div className="form-group contact">
                    <input type="email" onChange={(e) => handleChange(e)} className="form-control" name="email" placeholder="E-mail" />
                </div>

                <div className="form-group contact">
                    <input type="cellphone" className="form-control" name="cellphone" placeholder="Número de teléfono" onChange={(e) => handleChange(e)}/>
                </div>
        </form>
    );
}

export default PurchaseForm;
