import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'
import env from '../env'
import routes from '../router/routes';

export default function VerifyingPayment() {
    const [searchParams] = useSearchParams();
    const preapprovalId = searchParams.get("preapproval_id");
    const [count, setCount] = useState(0)

    useEffect(() => {
        axios.post(`${env.API_URL}/membershipPayment/verify`, { preapprovalId })
            .then(res => {
                window.location.href = '/#' + routes.dashboard;
                setCount(5)

                setInterval(() => {
                    setCount(count => count - .5)
                }, 1000)
                
                setTimeout(() => {
                    window.clearInterval();
                    window.location.reload();
                }, 5000)
            })
            .catch(err => {
                console.log(err);
            })
    }, [preapprovalId])

    return (
      <main style={{ marginTop: 120, height: '60vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{fontSize: 30, color: 'var(--color-success)'}}>
            Verificando pago... {parseInt(count) > 0 ? parseInt(count) : ''}
        </h1>
      </main>
    )
}