import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/logoSmall.png';
import env from '../../../env';
import routes from '../../../router/routes';
import '../../admin-panel/CreateForm.scss';
import { useNavigate } from 'react-router-dom';
import './RegisterForm.scss'
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import { authLogin } from '../../../redux/actions/auth.actions';

const RegisterForm = () => {
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        roles: []
    });
    const clientId = '63248102455-je3ia8b7ki4aftef0kfjovdmk5jl145t.apps.googleusercontent.com';
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(5);

    useEffect(() => {
        gapi.load('client:auth2', () => {
            gapi.auth2.init({
                client_id: clientId,
                scope: 'profile email',
            });
        });
    }, [clientId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if(error) setError(false);

        if(!form.firstName || !form.lastName || !form.email || !form.password || !form.confirmPassword || !form.username || !form.cellphone ) {
            setError('Todos los campos son obligatorios');
            setSuccess(false);
            setLoading(false);
            return;
        }
    
        const formData = new FormData();
        formData.append('firstName', form.firstName);
        formData.append('lastName', form.lastName);
        formData.append('email', form.email);
        formData.append('password', form.password);
        formData.append('confirmPassword', form.confirmPassword);
        formData.append('username' , form.username);
        formData.append('cellphone', form.phone);
        if (form.images) formData.append('images', form.images);

        axios.post(`${env.API_URL}/auth/register`, formData, {
            headers: {
                userid: auth._id,
                token: auth.token
            }
        })
        .then(res => {
            setLoading(false);
            setSuccess(true)
            setError(false);

            const timer = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(timer);
                navigate(routes.login);
            }, 5000);
        })
        .catch(err => {
            if(err.status === 400) {
                setError(err.response.data.message);
                setSuccess(false);
                setLoading(false);
                return;
            };

            setError(err.response.data.message)
            setSuccess(false);
            setLoading(false);
        });
    }


    const handleGoogleLogin = async (data) => {
        setLoading(true);

        axios.post(`${env.API_URL}/auth/google-oauth`, data)
            .then(res => {
                setLoading(false);
                dispatch( authLogin(res.data) );
                navigate(routes.dashboard);
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });

        if(error) setError(false);
    }
    
    return (
        <form action="" id='create-category-form'>
            <img src={logo} alt="Logo Networking" style={{width: 100, height: "auto"}} />

            <GoogleLogin 
                clientId={clientId}
                buttonText="Registrate con Google"
                onSuccess={response => handleGoogleLogin(response.profileObj)}
                onFailure={response => console.error(response)}
                cookiePolicy={'single_host_origin'}
            />

            <hr style={{width: "80%", margin: "50px 0", border: "dashed 1px #777777", color: "#777777"}} />

            <div className="form-group">
                <label htmlFor="firstName">Nombre</label>
                <input type="text" name="firstName" id="firstName" className="form-control" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form-group">
                <label htmlFor="lastName">Apellido</label>
                <input type="text" name="lastName" id="name" className="form-control" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form-group">
                <label htmlFor="username">Nombre de usuario</label>
                <input type="text" name="username" id="username" className="form-control" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form-group">
                <label htmlFor="cellphone">Teléfono celular</label>
                <input type="text" name="cellphone" id="cellphone" className="form-control" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form-group">
                <label htmlFor="email">E-Mail</label>
                <input type="text" name="email" id="email" className="form-control" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form-group">
                <label htmlFor="password">Contraseña</label>
                <input type="password" name="password" id="password" className="form-control" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form-group">
                <label htmlFor="confirmPassword">Confirmar contraseña</label>
                <input type="password" name="confirmPassword" id="confirmPassword" className="form-control" onChange={(e) => handleChange(e)} />
            </div>

            <div className="form-group">
                <label htmlFor="image">Imágen de perfil</label>
                <input type="file" name="image" id="image" className="form-control" onChange={(e) => setForm({
                    ...form,
                    images: e.target.files[0]
                })}/>
            </div>
            
            {!success && !loading && <button type="submit" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Registrarse</button>}
            
            {loading && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <TailSpin color='black' />    
            </div>}

            {success && <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <p className="text-success">Registrado correctamente, redireccionando en {seconds} segundos.</p>
            </div>}

            {error && <p className="text-danger">{error}</p>}
        </form>
    );
}

export default RegisterForm;
