import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./AdminPanel.scss";
import { Link } from "react-router-dom";
import routes from "../../router/routes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const adminPanelActions = [
  {
    name: "Lista de usuarios",
    category: "manage",
    path: routes.manageUsers,
    admin: true,
    moderator: false,
  },
  {
    name: "Lista de productos",
    category: "manage",
    path: routes.manageProducts,
    admin: true,
    moderator: true,
  },
  {
    name: "Gestionar pedidos",
    category: "manage",
    path: routes.manageOrders,
    admin: true,
    moderator: true,
  },
  {
    name: "Gestionar cupones",
    category: "manage",
    path: routes.manageCoupons,
    admin: true,
    moderator: true,
  },
  {
    name: "Editar usuario administrador",
    category: "manage",
    path: routes.editAdmin,
    admin: true,
    moderator: false,
  },
  {
    name: "Administrar noticias",
    category: "manage",
    path: routes.manageNews,
    admin: true,
    moderator: true,
  },
  {
    name: "Administrar categorías",
    category: "manage",
    path: routes.manageCategories,
    admin: true,
    moderator: true,
  },
  {
    name: "Administrar información de la empresa",
    category: "manage",
    path: routes.manageInfo,
    admin: true,
    moderator: false,
  },
  {
    name: "Crear usuario",
    category: "create",
    path: routes.createModUser,
    admin: true,
    moderator: false,
  },
  {
    name: "Crear producto",
    category: "create",
    path: routes.createProduct,
    admin: true,
    moderator: true,
  },
  {
    name: "Crear cupón",
    category: "create",
    path: routes.createCoupon,
    admin: true,
    moderator: true,
  },
  {
    name: "Crear noticia",
    category: "create",
    path: routes.createNews,
    admin: true,
    moderator: true,
  },
  {
    name: "Crear FAQ",
    category: "create",
    path: routes.createFAQ,
    admin: true,
    moderator: true,
  },
  {
    name: 'Crear imágen de "marcas"',
    category: "create",
    path: routes.createBrand,
    admin: true,
    moderator: true,
  },
  {
    name: "Manejar Comentarios",
    category: "manage",
    path: routes.manageComments,
    admin: true,
    moderator: true,
  },
  {
    name: "Buscar QR por link",
    category: "manage",
    path: routes.manageFind,
    admin: true,
    moderator: true,
  },
  {
    name: "Manejar anuncio principal",
    category: "manage",
    path: routes.manageAnouncement,
    admin: true,
    moderator: true,
  },
  {
    name: "Manejar precios de envío",
    category: "manage",
    path: routes.manageShippingPrice,
    admin: true,
    moderator: true,
  },
  {
    name: "Crear ticket de link",
    category: "create",
    path: routes.createLinkTicket,
    admin: true,
    moderator: true
  },
  {
    name: "Manejar tickets de link",
    category: "manage",
    path: routes.manageLinkTickets,
    admin: true,
    moderator: true
  },
  {
    name: "Manejar membresías de empresas",
    category: "manage",
    path: routes.adminEnterpriseMembership,
    admin: true
  },
  {
    name: "Cambiar foto de portada por defecto",
    category: "manage",
    path: routes.manageCoverPhotoDefault,
    admin: true
  }
];

const AdminPanelContent = () => {
  const [manageActions, setManageActions] = useState([]);
  const [createActions, setCreateActions] = useState([]);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setManageActions(
      adminPanelActions.filter((action) => action.category === "manage")
    );
    setCreateActions(
      adminPanelActions.filter((action) => action.category === "create")
    );
  }, []);

  const transitionTime = 0.5;

  if (auth.roles.includes('admin')) return (
    <motion.div className="admin_panel">
      <motion.div className="admin_panel__manage">
        <h2 className="admin_panel__manage__title">Gestión</h2>
        <ul className="admin_panel__manage__list">
          {manageActions.map((action, index) => {
            if (action.admin) return (
              <motion.li
                transition={{ duration: transitionTime + index * 0.08 }}
                animate={{ bottom: 0 }}
                className="admin_panel__manage__list__item"
                key={action.name}
              >
                <Link
                  className="admin_panel__manage__list__item__link"
                  to={action.path}
                >
                  {action.name}
                </Link>
              </motion.li>
            )
          })}
        </ul>
      </motion.div>

      <motion.div className="admin_panel__create">
        <h2 className="admin_panel__create__title">Creación</h2>
        <ul className="admin_panel__create__list">
          {createActions.map((action, index) => (
            <motion.li
              transition={{ duration: transitionTime + index * 0.1 }}
              animate={{ top: 0 }}
              className="admin_panel__create__list__item"
              key={action.name}
              onClick={() => navigate(action.path)}
            >
              <Link
                className="admin_panel__create__list__item__link"
                to={action.path}
              >
                {action.name}
              </Link>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </motion.div>
  );

  if (auth.roles.includes('moderator')) return (
    <motion.div className="admin_panel">
      <motion.div className="admin_panel__manage">
        <h2 className="admin_panel__manage__title">Gestión</h2>
        <ul className="admin_panel__manage__list">
          {manageActions.map((action, index) => {
            if (action.moderator) return (
              <motion.li
                transition={{ duration: transitionTime + index * 0.08 }}
                animate={{ bottom: 0 }}
                className="admin_panel__manage__list__item"
                key={action.name}
              >
                <Link
                  className="admin_panel__manage__list__item__link"
                  to={action.path}
                >
                  {action.name}
                </Link>
              </motion.li>
            )
          })}
        </ul>
      </motion.div>

      <motion.div className="admin_panel__create">
        <h2 className="admin_panel__create__title">Creación</h2>
        <ul className="admin_panel__create__list">
          {createActions.map((action, index) => (
            <motion.li
              transition={{ duration: transitionTime + index * 0.1 }}
              animate={{ top: 0 }}
              className="admin_panel__create__list__item"
              key={action.name}
              onClick={() => navigate(action.path)}
            >
              <Link
                className="admin_panel__create__list__item__link"
                to={action.path}
              >
                {action.name}
              </Link>
            </motion.li>
          ))}
        </ul>
      </motion.div>
    </motion.div>
  );
};

export default AdminPanelContent;
