import React, { useEffect, useState } from "react";
import axios from "axios";
import env from "../../env";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MercadoPagoMembresia = ({
  cart,
  setPaymentLink,
  membership,
  period,
  couponCode,
  paymentLink,
}) => {
  const auth = useSelector((state) => state.auth);
  //Mercado Pago

  const getData = async () => {
    const response = await axios.post(`${env.API_URL}/membershipPayment`, {
      cart,
      userid: auth._id,
      membershipid: membership._id,
      period: period,
      coupon: couponCode,
    });
    const data = response.data;
    setPaymentLink(data.init_point);
  };

  useEffect(() => {
    getData();
  }, [cart, membership._id, period, couponCode]);

  return (
    <>
      {paymentLink && (
        <a
          href={paymentLink}
          target="_blank"
          className="main_button"
          style={{
            textAlign: "center",
            fontSize: "1rem",
            width: "200px",
            height: "40px",
            borderRadius: "5px",
            marginLeft: "1rem",
            p:0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          Pagar
        </a>
      )}
    </>
  );
};

export default MercadoPagoMembresia;
