import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSelector } from 'react-redux';

const CheckIfMembership = ({children, value, additionalText, additionalOnClick}) => {
    const auth = useSelector(state => state.auth);

    const check = () => {
        const permission = auth.membership[0].permissions.find(permission => permission.permission === value);
        
        return permission.access
    }

    return (
        <>
            {
                check() ? children : <div className='block' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <p>Debes ser actualizar tu membresía. <FontAwesomeIcon icon={faLock} /></p>

                    {additionalText && <span style={{
                        marginLeft: '1rem',
                        cursor: 'pointer',
                        color: 'blue',
                    }} onClick={() => additionalOnClick()}>{additionalText}</span>}
                </div>
            }
        </>
    );
}

export default CheckIfMembership;
