import React, { useState } from 'react';
import PaymentOptions from '../components/shoppingCart/PaymentOptions';
import ProductsContainer from '../components/shoppingCart/ProductsContainer';
import { useSelector } from 'react-redux';
import './ShoppingCart.scss'
import PurchaseForm from '../components/shoppingCart/PurchaseForm';

const ShoppingCart = () => {
    const auth = useSelector(state => state.auth);
    const [success, setSuccess] = useState(false);
    const [form, setForm] = useState({});

    return (
        <main data-animation="appear" style={{ marginTop: 150 }}>
            <h1 style={{textAlign: 'center', margin: '0 0 50px 0', fontSize: '40px', fontWeight: 'bold'}}>Tu carrito de compras</h1>

            {success ? <div>
                <h2 style={{textAlign: 'center', margin: '100px 0 50px 0', fontSize: '40px', fontWeight: 'bold'}}>¡Compra exitosa!</h2>
            </div> : 
            <div className="container" style={{display: 'flex', padding: '40px', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <PurchaseForm form={form} setForm={setForm} />
                <ProductsContainer products={auth.shoppingCart}/>
                <PaymentOptions products={auth.shoppingCart} setSuccess={setSuccess} form={form}/>
            </div>}
        </main>
    );
}

export default ShoppingCart;