import axios from 'axios';
import React from 'react';
import { useSelector } from 'react-redux';
import env from '../../env';
import './ShoppingCart.scss'
import { useDispatch } from 'react-redux';
import { authLogin } from '../../redux/actions/auth.actions';
import { Link } from 'react-router-dom';
import router from '../../router/routes';

const formatNumbers = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = '$1,';
    return number.toString().replace(exp,rep);
}

const ProductsContainer = ({products}) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const handleRemoveFromCart = (product, position) => {
        axios.put(`${env.API_URL}/users/${auth._id}/updateShoppingCart`, {
            type: 'remove',
            product,
            position
        })
        .then(res => {
            axios.post(`${env.API_URL}/auth/login/identifyUser`, {
                token: auth.token
            })
                .then(res => dispatch(authLogin({
                    ...res.data,
                    token: auth.token
                })))
                .catch(err => console.error(err))

        } )
        .catch(err => {
            console.error(err);
        })


    }

    if(products.length === 0 ) return (
        <div className='products_container' style={{width: '100%'}}>
            <h2 style={{textAlign: 'center', width: '100%', margin: '0px 0', fontSize: '30px', fontWeight: '100'}}>¡Ups! Parece no haber productos en tu carrito</h2>
            <h2 style={{marginTop: 30, textAlign: 'center', width: '100%', margin: '0px 0', fontSize: '30px', fontWeight: '100',}}>¿Qué te parece <Link to={router.products} style={{ color: 'var(--card-color)'}}>ir a ver algunos productos</Link>?</h2>
        </div>
    );
    
    if(products && products.length > 0) return (
        <div className='products_container'>
            <div className="info">
                <p>Imágen</p>
                <p>Producto</p>
                <p>Precio individual</p>
                <p>Cantidad</p>
                <p>Total</p>
            </div>

            {
                products.map((product, index) => (
                    <div key={index} className='product_card'>
                        <button onClick={(e) => {
                            handleRemoveFromCart(product, index); //agregar index para eliminar elemento en esa posición
                        }} className='quit_from_cart'>

                            Quitar del carrito

                        </button>

                        <img src={product.principalImage} alt={product.name} />

                        <div className="product_info" >
                            <h2 style={{}}>{product.name}</h2>
                            <h3 style={{}}>${formatNumbers(parseInt(product.priceWithOffer || product.price))}</h3>
                            <h4 style={{}}>{product.quantity}</h4>
                            <h3 style={{}}>${formatNumbers(parseInt(product.priceWithOffer * product.quantity || product.price * product.quantity))}</h3>
                        </div>

                    </div>
                ))
            }        
        </div>
    );
}

export default ProductsContainer;
