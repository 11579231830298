import React, { useContext } from 'react';
import CardDataContext from './CardData.provider';
import axios from 'axios';
import env from '../../env'
import { useState } from 'react';

const LinkTicketModal = ({handleLinkTicketModal}) => {
    const { cardData, handleInputs, setCardData } = useContext(CardDataContext);
    const [error, setError] = useState(false)

    const handleSendData = (e) => {
        e.preventDefault();

        if(!cardData.ticketCode) {
            setError('Debe ingresar un código para poder continuar.');
            return;
        }

        axios.get(`${env.API_URL}/linkTicket/${cardData.ticketCode}`)
            .then(res => {
                if (res.data) {
                    setCardData({
                        ...cardData,
                        cardLink: res.data.data.link,
                    });

                    handleLinkTicketModal(false);
                }
            })
            .catch(err => {
                setCardData({
                    ...cardData
                });

                setError(err.response.data.message)
            }
        );
    }

    return (
        <div style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: '100',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <form style={{
                width: '50%',
                backgroundColor: 'white',
                borderRadius: '1rem',
                padding: '2rem',
            }}>
                <h2>Ingrese el código de su biznes prepago.</h2>

                <div className="cardlink_input" style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <input type="text" name="ticketCode"placeholder='Código de su ticket' id="ticketCode" value={cardData.ticketCode} onChange={(e) => handleInputs(e)} />
                </div>

                <div className="buttons" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '2rem',
                    width: '100%',
                }}>
                    <button type="submit" style={{
                        width: '45%',
                        padding: '1rem',
                        backgroundColor: 'var(--color-success)',
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '1rem',
                    }} onClick={(e) => handleSendData(e)}>Guardar</button>

                    <button type="button" style={{
                        width: '45%',
                        padding: '1rem',
                        backgroundColor: 'var(--color-danger)',
                        border: 'none',
                        cursor: 'pointer',
                        borderRadius: '1rem',
                    }} onClick={() => handleLinkTicketModal(false)}>Cancelar</button>
                </div>

                {error && <p style={{ color: 'var(--color-danger)', textAlign: 'center', marginTop: '30px', fontSize: '20px' }}>{error}</p>}
            </form>
        </div>
    );
}

export default LinkTicketModal;