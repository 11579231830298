import React from 'react'
import { HashLoader } from 'react-spinners';

export default function LoadingModal({}) {
  return (
    <section style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)',
      zIndex: 999999999999999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
  }}>

    <HashLoader color={'white'} size={100} />
  </section>
  )
}
