import React from "react";
import CreateHeroCardForm from "../../components/admin-panel/create-herocard/CreateHeroCardForm";

const CreateHeroCard = () => {
  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ margin: "30px 0", fontSize: "20px" }}>
        Crear tarjeta de presentación
      </h1>

      <CreateHeroCardForm />
    </div>
  );
};

export default CreateHeroCard;
