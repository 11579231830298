import React from "react";
import CreateTicketForm from "../../components/admin-panel/create-coupon/CreateTicketForm";

const CreateTicket = () => {
  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Crear ticket de link
      </h1>

      <CreateTicketForm />
    </div>
  );
};

export default CreateTicket;
