import React, { useEffect } from 'react';
import { Routes, Route, HashRouter, BrowserRouter} from "react-router-dom";
import env from '../env';
import { useDispatch } from 'react-redux';
import { authLogin } from '../redux/actions/auth.actions';
import axios from 'axios';
import NavbarRenderer from '../globals/navbar/NavbarRenderer';
import routes from './routes';
import ManageFind from '../pages/admin/manage/ManageFind';
import UserHistory from '../pages/UserHistory';
import ManageComments from '../pages/admin/manage/ManageComments';
import PaymentSuccess from '../pages/PaymentSuccess';
import ViewOrder from '../pages/admin/manage/ViewOrder';
import ManageBiznesInfo from '../pages/admin/manage/ManageBiznesInfo';
import CreateBrand from '../pages/admin/CreateBrand';
import CreateFAQ from '../pages/admin/CreateFAQ';
import CreateHeroCard from '../pages/admin/CreateHeroCard';
import FAQS from '../pages/FAQS';
import TermsAndConditions from '../pages/TermsAndConditions';
import AboutWe from '../pages/AboutWe';
import ActualizeMembership from '../pages/ActualizeMembership';
import CreateMembershipPage from '../pages/admin/CreateMembershipPage';
import Comment from '../pages/Comment';
import Register from '../pages/Register';
import UserConfig from '../pages/UserConfig.jsx';
import CardPage from '../pages/CardPage';
import UserSearch from '../pages/UserSearch';
import EditAdminForm from '../pages/admin/manage/EditAdminForm';
import ManageOrders from '../pages/admin/manage/ManageOrders';
import ManageProducts from '../pages/admin/manage/ManageProducts';
import ManageUsers from '../pages/admin/manage/ManageUsers';
import ManageCoupons from '../pages/admin/manage/ManageCoupons';
import ManageNews from '../pages/admin/manage/ManageNews';
import ManageCategories from '../pages/admin/manage/ManageCategories';
import CreateModUser from '../pages/admin/CreateModUser';
import CreateNews from '../pages/admin/CreateNews';
import CreateCoupon from '../pages/admin/CreateCoupon';
import CreateProduct from '../pages/admin/CreateProduct';
import CreateCategory from '../pages/admin/CreateCategory';
import ProtectedRoute from '../globals/navbar/ProtectedRoute';
import AdminPanel from '../pages/AdminPanel';
import Dashboard from '../pages/Dashboard';
import ShoppingCart from '../pages/ShoppingCart';
import UserPage from '../pages/UserPage';
import Products from '../pages/Products';
import Product from '../pages/Product';
import Login from '../pages/Login';
import Home from '../pages/Home';
import EditAnouncement from '../pages/admin/manage/EditAnouncement';
import ManageShippingPrice from '../pages/admin/manage/ManageShippingPrice';
import PageNotFound from '../pages/PageNotFound';
import UserContact from '../pages/UserContact';
import UserEnterprisePage from '../pages/UserEnterprisePage';
import VerifyingPayment from '../pages/VerifyingPayment';
import AdminPanelContent from "../components/admin-panel/AdminPanelContent"
import CreateTicket from '../pages/admin/CreateTicket';
import ManageTicketsLinks from '../pages/admin/manage/ManageTicketsLinks';
import ManageEnterpriseMembership from '../pages/admin/manage/ManageEnterpriseMembership';
import CoverPhoto from '../pages/admin/manage/CoverPhoto.jsx';

const AppRouter = () => {
    const dispatch = useDispatch();


    const identifyUser = () => {
        const token = localStorage.getItem('token');

        if(!token) return

        axios.post(`${env.API_URL}/auth/login/identifyUser`, {token})
        .then(res =>{
            dispatch( authLogin({
                ...res.data,
                token
            }) )
        })
        .catch(err => console.log(err));
    };

    useEffect(() => {
        identifyUser();
    }, []);

    return (
        <BrowserRouter>
                <Routes>
                    <Route exact path={routes.cardPage} element={<CardPage />} />

                    <Route element={<NavbarRenderer />} >                    
                        {/* USER ROUTES */}
                        <Route exact path={routes.home} element={<Home/>} />
                        <Route exact path={routes.products} element={<Products />} />
                        <Route exact path={routes.product} element={<Product />} />
                        <Route exact path={routes.login} element={<Login />} />
                        <Route exact path={routes.cart} element={<ShoppingCart />} />
                        <Route exact path={routes.dashboard} element={<Dashboard />} />
                        <Route exact path={routes.userEnterprise} element={ <UserEnterprisePage /> } />
                        <Route exact path={routes.userPage} element={<UserPage />} />
                        <Route exact path={routes.userSearch} element={<UserSearch />} />
                        <Route exact path={routes.userConfig} element={<UserConfig />} />
                        <Route exact path={routes.register} element={<Register />} />
                        <Route exact path={routes.successPayment} element={<Register />} />
                        <Route exact path={routes.comment} element={<Comment />} />
                        <Route exact path={routes.actualizeMembership} element={<ActualizeMembership />} />
                        <Route exact path={routes.aboutWe} element={<AboutWe />} />
                        <Route exact path={routes.termsAndConditions} element={<TermsAndConditions />} />
                        <Route exact path={routes.faqs} element={<FAQS />} />
                        <Route exact path={routes.paymentSuccess} element={<PaymentSuccess />} />
                        <Route exact path={routes.userHistory} element={<UserHistory />} />
                        <Route exact path={routes.order} element={<ViewOrder />} />
                        <Route exact path={routes.contacts} element={<UserContact />} />
                        <Route exact path={routes.verifyingPayment} element={<VerifyingPayment />} />

                        {/* ADMIN ROUTES */}
                        <Route element={ 
                            <ProtectedRoute condition={'admin'}>
                                <AdminPanel />
                            </ProtectedRoute> 
                        }>
                                <Route exact path={routes.manageLinkTickets} element={<ManageTicketsLinks />}/>
                                <Route exact path={routes.adminPanel} element={<ProtectedRoute condition={'admin'}> <AdminPanelContent /> </ProtectedRoute>}/>
                                <Route exact path={routes.createLinkTicket} element={<ProtectedRoute condition={'admin'}> <CreateTicket /> </ProtectedRoute>}/>
                                <Route exact path={routes.createCategory} element={<ProtectedRoute condition={'admin'}> <CreateCategory /> </ProtectedRoute>}/>
                                <Route exact path={routes.createProduct} element={<ProtectedRoute condition={'admin'}><CreateProduct /> </ProtectedRoute>}/>
                                <Route exact path={routes.createCoupon} element={<ProtectedRoute condition={'admin'}><CreateCoupon /> </ProtectedRoute>}/>
                                <Route exact path={routes.createNews} element={<ProtectedRoute condition={'admin'}><CreateNews /> </ProtectedRoute>}/>
                                <Route exact path={routes.createModUser} element={<ProtectedRoute condition={'admin'}><CreateModUser /> </ProtectedRoute>}/>
                                <Route exact path={routes.createMembership} element={<ProtectedRoute condition={'admin'}> <CreateMembershipPage /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageCategories} element={<ProtectedRoute condition={'admin'}> <ManageCategories /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageNews} element={<ProtectedRoute condition={'admin'}> <ManageNews /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageCoupons} element={<ProtectedRoute condition={'admin'}> <ManageCoupons/> </ProtectedRoute> }/>
                                <Route exact path={routes.manageUsers} element={<ProtectedRoute condition={'admin'}> <ManageUsers /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageProducts} element={<ProtectedRoute condition={'admin'}> <ManageProducts /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageOrders} element={<ProtectedRoute condition={'admin'}> <ManageOrders /> </ProtectedRoute> }/>
                                <Route exact path={`${routes.manageOrders}/:id`} element={<ProtectedRoute condition={'admin'}> <ViewOrder /> </ProtectedRoute> }/>
                                <Route exact path={routes.editAdmin} element={<ProtectedRoute condition={'admin'}> <EditAdminForm /> </ProtectedRoute> }/>
                                <Route exact path={routes.createHeroCard} element={<ProtectedRoute condition={'admin'}> <CreateHeroCard /> </ProtectedRoute> }/>
                                <Route exact path={routes.createFAQ} element={<ProtectedRoute condition={'admin'}> <CreateFAQ /> </ProtectedRoute> }/>
                                <Route exact path={routes.createBrand} element={<ProtectedRoute condition={'admin'}> <CreateBrand /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageInfo} element={<ProtectedRoute condition={'admin'}> <ManageBiznesInfo /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageComments} element={<ProtectedRoute condition={'admin'}> <ManageComments /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageFind} element={<ProtectedRoute condition={'admin'}> <ManageFind /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageAnouncement} element={<ProtectedRoute condition={'admin'}> <EditAnouncement /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageShippingPrice} element={<ProtectedRoute condition={'admin'}> <ManageShippingPrice /> </ProtectedRoute> }/>
                                <Route exact path={routes.adminEnterpriseMembership} element={<ProtectedRoute condition={'admin'}> <ManageEnterpriseMembership /> </ProtectedRoute> }/>
                                <Route exact path={routes.manageCoverPhotoDefault} element={<ProtectedRoute condition={'admin'}> <CoverPhoto /> </ProtectedRoute> }/>
                        </Route>

                        <Route path={'/*'} element={<PageNotFound />} />
                </Route>

                <Route path='/:link' element={<CardPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;
