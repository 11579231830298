import React from 'react';
import '../user-search/UserSearch.scss';
import UserCard from '../user-search/UserCard';

const UserContactsSection = ({users, title}) => {
    return (
        <div style={{width: '100%', marginTop: 40}} className='user_search_container'>
            <h2>{title}</h2>

            <div className="users">
                {users.map(user => (
                    <UserCard user={user} />
                ))}
            </div>
        </div>
    );
}

export default UserContactsSection;