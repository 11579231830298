import axios from "axios";
import env from "../env";

const api = axios.create({
    baseURL: env.API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

const apiFunctions = {};

apiFunctions.deleteCard = async (id, userid) => {
    try {
        const response = await api.delete(`/card/${id}`, {
            headers: {
                userid
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}

// CONFIG

apiFunctions.getConfig = async () => {
    try {
        const response = await api.get(`/config`);
        return response.data;
    } catch (error) {
        throw error;
    }
}

apiFunctions.changeCoverPhoto = async (image) => {
    try {
        const formData = new FormData();
        formData.append("images", image);

        console.log(formData);

        const response = await api.post(`/config/coverPhoto`, formData);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export default apiFunctions;