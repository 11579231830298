import React, { useState, useEffect } from 'react';
import logo from '../../assets/logo.png';
import './CreateForm.scss';

const EditItemForm = ({item, inputs, handleSubmit, form, setForm, width,
errores, success, reset }) => {
    const [inputsFiles, setInputsFiles] = useState([]);
    const [otherInputs, setOtherInputs] = useState([]);

    useEffect(() => {
        const inputsFile = inputs.filter(input => input.type === 'file');
        const other = inputs.filter(input => input.type !== 'file');

        setInputsFiles(inputsFile);
        setOtherInputs(other);
    }, []);

    const onChange = (e) => {
        if(e.target.type === 'file') {
            setForm({...form, [e.target.name]: e.target.files[0]});
        } else {
            setForm({...form, [e.target.name]: e.target.value});
        }
    }

    return (
        <form action="" id='create-category-form' style={{width, backgroundColor: "white"}}>
            <img src={logo} alt="Logo Networking" />

            {
                otherInputs.map((input, index) => {
                    if(input.type !== 'textarea') return (
                        <div className="form-group">
                            <label htmlFor={input.name}>{input.label}</label>
                            <input defaultValue={item[input.name]} type={input.type} name={input.name} 
                            id={input.name} className="form-control" onChange={(e) => onChange(e)} 
                            style={{color: 'white'}} />
                        </div>
                    )

                    if(input.type === 'textarea') return (
                        <div className="form-group">
                            <label htmlFor={input.name}>{input.label}</label>
                            <textarea defaultValue={item[input.name]} name={input.name} id={input.name} cols="30" rows="30" 
                            className="form-control" style={{height: 50}} onChange={(e) => onChange(e)}></textarea>
                        </div>
                    )
                })
            }

            {
                inputsFiles.map((input, index) => {
                    return (
                        <div className="form-group">
                            <label htmlFor={input.name}>{input.label}</label>
                            <input type="file" name={input.name} id={input.name} className="form-control" 
                            onChange={(e) => onChange(e)}/>
                        </div>
                    )
                })
            }

            <div className="buttons" style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                <button style={{width: '48%'}} type="button" className="btn btn-danger" onClick={() => reset()}>CANCELAR</button>
                <button style={{width: '48%'}} type="submit" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>EDITAR</button>
            </div>

            {success && <p className="text-success">{success}</p>}

            {errores && <p className="text-danger">{errores}</p>}
        </form>
    );
}

export default EditItemForm;
