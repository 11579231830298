import React, { useState } from "react";
import axios from "axios";
import env from "../../../env";
import "./ManageFind.scss";

const ManageFind = () => {
  const [link, setLink] = useState("");
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${env.API_URL}/card/find/link`, { link })
      .then((res) => {
        setInfo(res.data);
      })
      .catch((err) => {
        setError(err.response.data.message);
      });
  };

  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        marginTop: "30px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Encontrar QR por link
      </h1>

      <div
        className="form-group"
        style={{
          width: "100%",
          maxWidth: "500px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <input
          type="text"
          name="link"
          className="form-control"
          id="link"
          placeholder="Link"
          style={{
            background: "none",
            border: "1px solid black",
            padding: 10,
            color: "black",
          }}
          onChange={(e) => setLink(e.target.value)}
        />

        <button
          type="submit"
          onClick={(e) => handleSubmit(e)}
          className="btn btn-primary"
          style={{ marginTop: "20px", padding: "10px 0", cursor: "pointer" }}
        >
          Buscar
        </button>
      </div>

      {error && (
        <div
          className="alert alert-danger"
          role="alert"
          style={{
            width: "100%",
            maxWidth: "500px",
            marginTop: "20px",
            color: "var(--color-danger)",
          }}
        >
          {error}
        </div>
      )}

      {info && (
        <div
          className="info"
          style={{
            width: "100%",
            maxWidth: "500px",
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={info.qr} alt="QR Encontrado" />
          <h3 style={{ marginTop: "20px" }}>
            {info.user.firstName} {info.user.lastName}
          </h3>
          <p>{info.user.email}</p>

          <a href={info.qr} id="download_qr_finded">
            Descargar
          </a>
        </div>
      )}
    </div>
  );
};

export default ManageFind;
