import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Anoucement.scss';
import env from '../../../env';

const Anoucement = ({anoucementData}) => {
    const [data, setData] = useState(false);

    useEffect(() => {
        axios.get(`${env.API_URL}/anouncement`)
            .then(res => {
                setData(res.data)
            })
            .catch(err => console.error(err));
    }, []);


    if(data) return (
        <div className='anoucement_container'>
            <img src={data.image} alt="anoucement" />

            <div className="data_container">
                <p id="data_disclaimer">{data.disclaimer}</p>

                <div className="title">
                    <h2>{data.titlePartOne}</h2>

                    <h2 className='description'><span style={{color: 'var(--card-color)', marginRight: 20}}>{data.titleDestacado}</span> 
                    {data.titleDestacadoTwo}
                    </h2>

                    <h2>{data.titlePartTwo}</h2>
                </div>

                <p id='anouncement_description'>
                    {data.descriptionOne} 
                </p>

                <p id='anouncement_description'>{data.descriptionTwo}</p>

                <div className="buttons">
                    <Link to={data.buttonOneLink} className="main_button" style={{marginRight: 10}}>{data.buttonOneText}</Link>
                    
                    <Link href={data.buttonTwoLink} className="secondary_button">{data.buttonTwoText}</Link>
                </div>
            </div>
        </div>
    );
}

export default Anoucement;
