import React, { useEffect, useState } from "react";
import axios from 'axios';
import env from "../../env";
import { useSelector } from "react-redux";

const MercadoPagoPaymentCompra = ({ cart, setPaymentID, paymentID, couponSelected, form }) => {
  const auth = useSelector(state => state.auth);
  const [state, setState] = useState(false);
  const [dontRender, setDontRender] = useState(false);
  //Mercado Pago
  const FORM_ID = "payment-form";

  const getData = async () => {
    const response = await axios.post(`${env.API_URL}/payments`, { 
      cart,
      buyer: auth._id,
      coupon: couponSelected ,
      region: form.region,
      commune: form.commune,
      address: form.address,
      email: form.email,
      cellphone: form.cellphone,
    });
    const data = response.data;
    if(!paymentID){
      setDontRender(false);
      setPaymentID(data.id);
    } else {
      setDontRender(true);
    }
  }


  useEffect(() => {
    if (paymentID && !state) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://www.mercadopago.cl/integrations/v1/web-payment-checkout.js";
      script.setAttribute("data-preference-id", paymentID);
      const form = document.getElementById(FORM_ID);
      form.appendChild(script);
      setState(true);
    }
  }, [paymentID]);

  useEffect(() => {
    if (paymentID === null ) {
      getData();
    }

    return () => {
      setPaymentID(null);
    };
  }, []);

  return (
    <>
      <form id={FORM_ID} method="GET" />
    </>
  );
};

export default MercadoPagoPaymentCompra;