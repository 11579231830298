import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import regiones from "../../../components/shoppingCart/regiones";
import env from "../../../env";

const ManageShippingPrice = () => {
  const auth = useSelector((state) => state.auth);
  const [form, setForm] = useState({});
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .put(`${env.API_URL}/shippingPrice/${form.region}`, form, {
        headers: {
          userid: auth._id,
          token: auth.token,
        },
      })
      .then((res) => {
        setSuccess(true);
        setError(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setSuccess(false);
      });
  };

  return (
    <div
      className="container"
      style={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 50,
      }}
    >
      <h1 style={{ marginBottom: "30px", fontSize: "20px" }}>
        Modificar Precios de envío
      </h1>

      <form action="">
        <div
          className="form-group"
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <label style={{ margin: 10 }} htmlFor="region">
            Región
          </label>
          <select
            name="region"
            id="region"
            className="form-control"
            onChange={(e) => handleChange(e)}
            style={{
              background: "#ccc",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              marginBottom: 20,
              color: "black",
            }}
          >
            <option value="" disabled selected>
              Seleccione una región
            </option>
            {regiones.map((region) => (
              <option value={region.region}>{region.region}</option>
            ))}
          </select>
        </div>

        <div
          className="form-group"
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <label style={{ margin: 10 }} htmlFor="price">
            Precio
          </label>
          <input
            type="number"
            name="price"
            id="price"
            className="form-control"
            onChange={(e) => handleChange(e)}
            style={{
              background: "#ccc",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              marginBottom: 20,
              color: "black",
            }}
          />
        </div>

        {error && (
          <p style={{ color: "red", margin: 20, textAlign: "center" }}>
            Ha ocurrido un error
          </p>
        )}
        {success && (
          <p style={{ color: "green", margin: 20, textAlign: "center" }}>
            Se ha actualizado el precio de envío
          </p>
        )}

        <button
          onClick={(e) => handleSubmit(e)}
          style={{
            background: "var(--card-color)",
            border: "none",
            padding: "10px 20px",
            width: "100%",
            cursor: "pointer",
            borderRadius: 50,
          }}
          type="submit"
        >
          Actualizar
        </button>
      </form>
    </div>
  );
};

export default ManageShippingPrice;
