import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Link, useParams } from 'react-router-dom';
import env from '../env';
import {motion } from 'framer-motion';
import './CardPage.scss'
import SocialMedia from '../components/card/CardModalSocialMedia.data';
import CustomMedia from '../components/card/CardModalCustomMedia.data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/logo.png';
import logoSmall from '../assets/logoSmall.png';

const CardPage = () => {
    const { link } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [card, setCard] = useState({});

    const greyImage = 'https://res.cloudinary.com/syphhy/image/upload/v1672684987/grey-background-07_w4ukhq.jpg';

    const findIconSocial = (name) => {
        let icon = SocialMedia.find((socialMedia) => socialMedia.name === name);
        if (!icon) icon = CustomMedia.find((socialMedia) => socialMedia.name === name);
        return icon.icon;
    }

    useEffect(() => {
        setIsLoading(true);

        axios.get(`${env.API_URL}/card/${link}`)
            .then((res) => {
                setCard(res.data.card);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });

    }, []);
    
    if(isLoading) return (
        <div className='loading_container'>
            <h1>Loading...</h1>
            <TailSpin color='black' />
        </div>
    );

    if(!isLoading) return (
        <div className="container_card_pc" style={{minHeight: '100vh', backgroundColor: card.cardStyle.body.backgroundColor || 'white' }}> 
            <div className='preview_content' style={{backgroundColor: card.cardStyle.body.backgroundColor || 'white'}}>
                <motion.div animate={{transform: 'scale(1)'}} className="preview_header">
                    <img src={card.coverPhoto !== "false" ? card.coverPhoto : logo} alt="Prevista portada" id='preview_cover_photo' />

                    <motion.div animate={{transform: 'scale(1)'}} className="preview_header_user">
                        <img src={card.perfilImage || greyImage} alt="Prevista foto de perfil" id='preview_profile_photo' 
                        style={card.cardStyle.profilePhoto}/>
                        <img src={card.logoPhoto !== "false" ? card.logoPhoto : logoSmall} alt="Prevista del logo" id='preview_profile_logo' 
                        style={card.cardStyle.logo}/>
                    </motion.div>
                </motion.div>

                <motion.div animate={{transform: 'scale(1)'}} className="preview_body">
                    <motion.div animate={{transform: 'scale(1)'}} className="preview_body_user">
                        <h2 id='preview_username' style={{...card.cardStyle.name, textAlign: card.cardStyle.name.textAlign}}>{card.name}</h2>

                        <motion.div animate={{transform: 'scale(1)'}} className="preview_body_user_job">
                            {!card.jobPosition && !card.jobEntity ? null :
                                <h3 id='preview_job_position' style={card.cardStyle.job}>{card.jobPosition || ''} - {card.jobEntity || ''}</h3>
                            }
                        </motion.div>

                        <motion.div animate={{transform: 'scale(1)'}} className="preview_body_user_email">
                            <h3 id='preview_email' style={card.cardStyle.email}><a href={`mailto:${card.email}`} style={{color: 'inherit'}}>{card.email || ''}</a></h3>
                        </motion.div>

                        <motion.div animate={{transform: 'scale(1)'}} className="preview_body_user_biography">
                            <p id='preview_biography' style={card.cardStyle.biography}>{card.biography || ''}</p>
                        </motion.div>
                    </motion.div>

                    {card.vcardWants &&
                        <motion.a href={card.vcard} animate={{transform: 'scale(1)', cursor: 'pointer'}} className="preview_body_vcard" style={{cursor: 'pointer'}}>
                            <button style={{...card.cardStyle.buttonVCard, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Agregar contacto</button>       
                        </motion.a>
                    }

                    {card.addContact && 
                        <motion.div animate={{transform: 'scale(1)'}} className="preview_body_vcard">
                            <button style={card.cardStyle.buttonContact}>
                                <a href={`tel:+${card.countryCode || '56'}${card.cellphone}`} style={{color: card.cardStyle.buttonContact.color, textDecoration: 'none', backgroundColor: 'none'}}>
                                    Llamar
                                </a>
                            </button>
                        </motion.div>
                    }

                {card.additionalButtons && card.additionalButtons[0] &&
                    card.additionalButtons.map(( data, index)=> (
                        <a  
                                key={index}
                                href={card.additionalButtons[index] && card.additionalButtons[index].link} 
                                target="_blank" 
                            >
                        <motion.div animate={{transform: 'scale(1)'}} className="preview_body_vcard">
                            
                            <button style={card.additionalButtons[index] && card.additionalButtons[index].styles && card.additionalButtons[index].styles}>
                                <p style={card.additionalButtons[index] && {
                                    ...card.additionalButtons[index].styles,
                                    backgroundColor: 'transparent',
                                    width: '100%',
                                    height: 'auto',
                                } }>
                                    {card.additionalButtons[index] && card.additionalButtons[index].text}
                                </p>
                            </button>

                        </motion.div>
                        </a>
                    ))
                }

                    <motion.div animate={{transform: 'scale(1)'}} className="preview_body_social">
                        <motion.div animate={{transform: 'scale(1)'}} className="preview_body_social_icons">
                            <motion.div animate={{transform: 'scale(1)'}} className="favorites">
                                {card.socialMedia.filter((el) => el.favorite).map((icon, index) => (
                                    <a href={icon.url} key={index} target='_blank' rel='noreferrer' style={{borderRadius: card.cardStyle.buttonSocialFavorite.borderRadius, 
                                    backgroundColor: card.cardStyle.buttonSocialFavorite.backgroundColor || icon.color}} 
                                    className="favorite">
                                        <FontAwesomeIcon icon={findIconSocial(icon.name)} style={{color: card.cardStyle.buttonSocialFavorite.color || icon.contrast}}/>
                                    </a>
                                ))}
                            </motion.div>


                            {card.socialMedia.filter((el) => !el.favorite).map((icon, index) => (
                                <a href={icon.url} key={index} target='_blank' rel='noreferrer' style={{borderRadius: card.cardStyle.buttonSocial.borderRadius, 
                                    backgroundColor: card.cardStyle.buttonSocial.backgroundColor || icon.color}} className="nofavorite">
                                    <FontAwesomeIcon icon={findIconSocial(icon.name)} style={{color: card.cardStyle.buttonSocial.color || icon.contrast}}/>
                                </a>
                            ))}
                        </motion.div>
                    </motion.div>
                </motion.div>

                {card.user.membership[0].name === "Básica" && <a href={env.FRONTEND_URL}><p style={{textAlign: 'center', color: 'rgb(70, 70, 70)'}}>Membresía básica <a style={{color: 'rgb(70, 70, 70)'}} href={env.FRONTEND_URL}>Biznes</a>, para conocer más visítenos en {env.FRONTEND_URL}.</p></a>}
            </div>
        </div>
    );
}

export default CardPage;
