export default {
    home: '',
    products: '/app/productos',
    product: '/app/productos/:id',
    login: '/app/iniciar-sesión',
    dashboard: '/app/mi-usuario',
    cart: '/app/carrito',
    adminPanel: '/app/admin-panel',
    notFound: '/*',
    userPage: '/app/usuario/:id',
    userPagePath: '/app/usuario',
    userEnterprise: '/app/usuario-empresa/:id',
    userEnterprisePath: '/app/usuario-empresa',
    userSearch: '/app/buscar-usuario',
    userConfig: '/app/configurar-usuario',
    register: '/app/registro',
    successPayment: '/app/success/:purchase_id/:buyer',
    comment: '/app/comentario',
    cardPage: '/card/:link',
    actualizeMembership: '/app/actualizar-membresia',
    aboutWe: '/app/sobre-nosotros',
    termsAndConditions: '/app/terminos-y-condiciones',
    faqs: '/app/preguntas-frecuentes',
    paymentSuccess: '/app/pago-exitoso',
    userHistory: '/app/historial',
    orderPath: '/app/orden',
    order: '/app/orden/:id',
    contacts: '/app/contactos',
    verifyingPayment: '/app/verificando-pago',

    // Admin Create/edit
    createCategory: '/admin-panel/create-category',
    editCategory: '/admin-panel/edit-category/:id',
    createProduct: '/admin-panel/create-product',
    editProduct: '/admin-panel/edit-product/:id',
    createCoupon: '/admin-panel/create-coupon',
    createNews: '/admin-panel/create-news',
    createModUser: '/admin-panel/create-mod-user',
    createMembership: '/admin-panel/create-membership',
    editAdmin: '/admin-panel/edit-admin',
    createMembership: '/admin-panel/create-membership',
    createHeroCard: '/admin-panel/create-presentation-card',
    createFAQ: '/admin-panel/create-faq',
    createBrand: '/admin-panel/create-brand',
    createLinkTicket: '/admin-panel/create-ticket',

    // Admin Manage
    manageCategories: '/admin-panel/manage-categories',
    manageProducts: '/admin-panel/manage-products',
    manageCoupons: '/admin-panel/manage-coupons',
    manageNews: '/admin-panel/manage-news',
    manageUsers: '/admin-panel/manage-users',
    manageOrders: '/admin-panel/manage-orders',
    manageInfo: '/admin-panel/manage-info',
    manageComments: '/admin-panel/manage-comments',
    manageFind: '/admin-panel/manage-find',
    manageAnouncement: '/admin-panel/manage-anouncement',
    manageShippingPrice: '/admin-panel/manage-shipping-price',
    manageLinkTickets: '/admin-panel/manage-link-tickets',
    adminEnterpriseMembership: '/admin-panel/manage-enterprise-membership',
    manageCoverPhotoDefault: '/admin-panel/manage-cover-photo-default',
}