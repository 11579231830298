import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import env from '../../../env';
import './CommentsSection.scss';
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const CommentsSection = () => {
    const [comments, setComments] = useState([]);
    const [active, setActive] = useState(false);
    const div = useRef()

    const changeForRandomComment = (array) => {
        setActive(false);
        const randomIndex = Math.floor(Math.random() * array.length);
        setActive(array[randomIndex]);
    }

    useEffect(() => {
        axios.get(`${env.API_URL}/comments`)
        .then(res => {
            setComments(res.data.filter(comment => comment.aproved));
            changeForRandomComment(res.data.filter(comment => comment.aproved))
        })
        .catch(err => console.log(err));
    }, []);

    if(active && active.author) return (
        <motion.div style={{backgroundColor: 'var(--card-color)'}} animate={{transform: 'scale(1)'}} ref={div}>
                <motion.div className='comment_card'>
                    <FontAwesomeIcon icon={faAngleLeft} color={'black'} size={50} className='left' onClick={() => changeForRandomComment(comments)} />

                    <motion.p animate={{transform: active ? 'scale(1)' : ''}} className='description'>"{active.comment}"</motion.p>
                    <motion.p animate={{transform: active ? 'scale(1)' : ''}} className='title'>{active.author.firstName} {active.author.lastName}</motion.p>

                    <FontAwesomeIcon icon={faAngleRight} color={'black'} size={50} className='right' onClick={() => changeForRandomComment(comments)} />
                </motion.div>
        </motion.div>
    );
}

export default CommentsSection;