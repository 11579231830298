import React, { useState } from 'react';
import './Dashboard.scss'
import {motion} from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo.png';
import logoSmall from '../../assets/logoSmall.png';
import env from '../../env';


export default function DashboardContentEnterprise({auth}) {
    const [qrActivated, setQrActivated] = useState(false);
    const transitionDuration = 1;

    return (
        <div className='dashboard_content'>
    
            {qrActivated && 
            <motion.div className="qr_modal_container" transition={{duration: transitionDuration}} animate={{opacity: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'}}>
                <img src={qrActivated} alt="IMÁGEN QR ACTIVADA" />
                <a href={qrActivated} download>Descargar QR</a>
                <FontAwesomeIcon icon={faXmark} onClick={(e) => setQrActivated(false)} />
            </motion.div>}
    
            <motion.div className="cards_container" transition={{duration: transitionDuration}} animate={{opacity: 1}}>
                {auth.cards.length > 0 && auth.cards.map((card, index) => (
                    <div className="card" key={index}>
                        <div className="card_dashboard_preview">
                            <div className="card_dashboard_preview__header">
                                <img style={{width: '100%', height: '100%', borderTopLeftRadius: 15, borderTopRightRadius: 15, borderBottomLeftRadius: 0}} 
                                src={card.coverPhoto || logo} alt="cover" />
                                
                                <div className="card_dashboard_preview__header__userphoto">
                                    <img className='perfil_photo' src={card.perfilImage || logoSmall} alt="user" />
                                    <img className='enterprise_logo' src={card.logoPhoto || logoSmall} alt="logo" />
                                </div>
                            </div>
                            
                            <div className="card_dashboard_preview__header__info">
                                    <h3 style={{textAlign: "center"}}>{card.name}</h3>
    
                                    <div className="position">
                                    {!card.jobPosition && !card.jobEntity ? null :
                                    <p id='preview_job_position' style={card.cardStyle.job}>{card.jobPosition || ''} - {card.jobEntity || ''}</p>
                                }
                                    </div>
                            </div>
    
                            <a className='link' target={'_blank'} href={`https://empresas-api.biznes.cl/api/card/${card.cardLink}`} style={{background: 'var(--color-logo)', color: 'black', fontWeight: '500'}}>Ver tarjeta</a>
    
                            <img src={card.imageQr} alt="imágen QR" style={{width: 50, height: 50, position: 'absolute', left: '40%', bottom: 20, cursor: 'pointer'}} 
                            onClick={(e) => setQrActivated(card.downloadQr)} />
                        </div>
                    </div>
                ))}
            </motion.div>
        </div>
      )
}
